import React, { useState, useEffect, useCallback } from 'react';
import styles from './scrollToTop.module.scss';
import DoubleUpArrow from '../../svgs/Arrow/DoubleUpArrow';

const ScrollToTop = () => {
  const [show, setShow] = useState(false);

  const scrollHeight = () =>
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > scrollHeight() / 4) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [scrollHeight]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.wrapper}>
      {show && (
        <button className={styles.button} onClick={goToTop}>
          <DoubleUpArrow />
        </button>
      )}
    </div>
  );
};
export default ScrollToTop;
