import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { imagePropTypes } from 'utils/proptypes';
import { className, getQueryParams, toVariableName } from '../../utils';
import styles from './inlineIcon.module.scss';

const InlineIcon = ({ name, icon }) => {
  const [link, setLink] = useState(null);
  const iconRef = useRef(null);

  const imageQueryParams = {
    w: icon.file.details.image.width,
    h: icon.file.details.image.height,
    fit: 'fill',
  };

  useEffect(() => {
    if (iconRef) {
      const el = iconRef?.current;
      const previousEl = el.previousElementSibling;

      previousEl?.tagName === 'A' &&
        setLink({
          href: previousEl.getAttribute('href'),
          rel: previousEl.getAttribute('rel'),
          target: previousEl.getAttribute('target'),
        });
    }
  }, [iconRef]);

  return link ? (
    <a
      {...className('component', styles.link)}
      data-automation-id={toVariableName(name)}
      href={link.href}
      rel={link.rel}
      target={link.target}>
      <img
        alt=""
        className={styles.icon}
        data-automation-id={toVariableName(name)}
        ref={iconRef}
        src={`${icon.file.url}${getQueryParams(imageQueryParams)}`}
      />
    </a>
  ) : (
    <img
      alt=""
      data-automation-id={toVariableName(name)}
      ref={iconRef}
      src={`${icon.file.url}${getQueryParams(imageQueryParams)}`}
    />
  );
};

InlineIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(imagePropTypes),
};

export default InlineIcon;
