import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className } from 'utils';
import { CUSTOM_RICH_TEXT_STYLING } from 'utils/constants';
import './customrichtext.css';

export const renderHtmlToReact = html => {
  const HtmlToReact = require('html-to-react');
  const HtmlToReactParser = require('html-to-react').Parser;
  const htmlInput = html;

  // https://www.npmjs.com/package/html-to-react#with-custom-processing-instructions
  const isValidNode = function() {
    return true;
  };

  // Order matters. Instructions are processed in the order they're defined
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      replaceChildren: false,
      shouldProcessNode: function(node) {
        return node.name && node.name === 'a';
      },
      processNode: function NodeLink(node, children, index) {
        return (
          <Link to={node.attribs.href} key={index}>
            {children}
          </Link>
        );
      },
    },
    {
      // Anything else
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parseWithInstructions(
    htmlInput,
    isValidNode,
    processingInstructions,
  );
  return reactElement;
};

const CustomRichText = ({ content, styling }) => (
  <div
    {...className(
      'custom-rich-text',
      styling &&
        styling.includes(CUSTOM_RICH_TEXT_STYLING.TABLES_NO_VERTICAL_BORDERS) &&
        'tables-no-vertical-borders',
      styling && styling.includes(CUSTOM_RICH_TEXT_STYLING.FLOAT_LEFT) && 'float-left',
      styling && styling.includes(CUSTOM_RICH_TEXT_STYLING.FLOAT_RIGHT) && 'float-right',
    )}>
    {renderHtmlToReact(content)}
  </div>
);

CustomRichText.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  styling: PropTypes.array,
};

export default CustomRichText;
