import React from 'react';
import PropTypes from 'prop-types';
import { className, renderText } from 'utils';
import EmailForm from './EmailForm';

import styles from './subscribe.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const Subscribe = ({ headline, content, hubspotFormId, ...props }) => (
  <section
    {...className(
      'component page-left-inset',
      ctaBaseStyles.cta,
      ctaBaseStyles.withBackground,
      props.className && props.className,
    )}>
    <div className={ctaBaseStyles.row}>
      <div className={styles.copy}>
        {headline && <h1 className={styles.headline}>{headline}</h1>}
        {content && <p className={styles.description}>{renderText({ json: content })}</p>}
      </div>
      {hubspotFormId && <EmailForm className={styles.emailForm} formId={hubspotFormId} />}
    </div>
  </section>
);

Subscribe.propTypes = {
  headline: PropTypes.string,
  content: PropTypes.object,
  hubspotFormId: PropTypes.string,
  className: PropTypes.string,
};

export default Subscribe;
