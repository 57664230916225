import React, { useRef, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { useArrayRef } from 'hooks';
import Dropdown from './Dropdown';
import { SearchIcon } from 'svgs';
import { className } from 'utils';
import { KEY_CODES } from 'utils/constants';
import styles from './desktop.module.scss';
import baseNavStyles from '../navigation.module.scss';

const DesktopNav = ({
  style,
  navItemsWithDropdowns,
  utilityLinks,
  buttonText,
  buttonLink,
  isMicrosite,
  searchPlaceholder,
}) => {
  const getStartedRef = useRef(null);

  const [isHoldingShift, setIsHoldingShift] = useState(false);
  const [dropdownRefs, setDropdownRefs] = useArrayRef();

  const focusIndex = index => {
    const element = dropdownRefs.current[index + 1];
    if (element) {
      element.focus();
    } else {
      getStartedRef.current.focus();
    }
  };

  const handleShiftUp = event => {
    if (event.keyCode === KEY_CODES.SHIFT) {
      setIsHoldingShift(false);
    }
  };

  const handleShiftDown = event => {
    if (event.keyCode === KEY_CODES.SHIFT) {
      setIsHoldingShift(true);
    }
  };

  /**
   * We track whether the user is traversing through the navigation
   * with tab and shift-tab
   */
  useEffect(() => {
    document.body.addEventListener('keyup', handleShiftUp);
    document.body.addEventListener('keydown', handleShiftDown);

    return () => {
      document.body.removeEventListener('keyup', handleShiftUp);
      document.body.removeEventListener('keydown', handleShiftDown);
    };
  }, []);

  return (
    <nav
      id="js-animate-nav"
      style={style}
      className={`${styles.nav}${isMicrosite && ` ${styles.msHeader}`}`}
      aria-label="Top Navigation">
      <ul className={styles.navLinks}>
        {navItemsWithDropdowns &&
          navItemsWithDropdowns.map((menuItem, i) => (
            <Dropdown
              key={i}
              isHoldingShift={isHoldingShift}
              focusNext={() => focusIndex(i)}
              focusPrevious={() => focusIndex(i - 1)}
              ref={setDropdownRefs(i)}
              menu={menuItem}
              isMicrosite
            />
          ))}
      </ul>
      {isMicrosite && (
        <search role="search">
          <form
            onSubmit={event => {
              event.preventDefault();

              // Capture and process form data
              const formData = new FormData(event.target);
              const searchInput = formData.get('search');

              // Null value check (empty input)
              if (!searchInput) {
                return;
              }

              // Route user to search page and populate query string
              const encodedQueryString = encodeURIComponent(searchInput);
              navigate(`/search?query=${encodedQueryString}`);
            }}>
            <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6081 15.4602L15.4482 16.6201L11.9678 13.1405L13.1277 11.9798L16.6081 15.4602ZM6.76484 11.6985C4.0514 11.6985 1.84323 9.49114 1.84323 6.77687C1.84323 4.06342 4.0514 1.85526 6.76484 1.85526C9.47829 1.85526 11.6865 4.06342 11.6865 6.77687C11.6865 9.49114 9.47829 11.6985 6.76484 11.6985ZM6.76485 0.214722C3.1409 0.214722 0.202698 3.15292 0.202698 6.77687C0.202698 10.4008 3.1409 13.339 6.76485 13.339C10.3888 13.339 13.327 10.4008 13.327 6.77687C13.327 3.15292 10.3888 0.214722 6.76485 0.214722Z" />
            </svg>
            <input type="search" name="search" placeholder={searchPlaceholder} />
          </form>
        </search>
      )}
      <Link to="/search" className={styles.searchIconLink}>
        <SearchIcon {...className(styles.searchIcon)} />
      </Link>
      {buttonLink && (
        <Link
          ref={getStartedRef}
          {...className(baseNavStyles.contact, styles.contact, styles.headerCta)}
          to={buttonLink}>
          {buttonText}
        </Link>
      )}
      {utilityLinks && !isMicrosite && (
        <ul className={styles.utilityLinkList}>
          {utilityLinks.map((item, i) => (
            <li key={i} className={styles.utilityLinkItem}>
              <Link
                activeClassName={baseNavStyles.activeLink}
                className={baseNavStyles.utilityLink}
                partiallyActive
                to={item.to}>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

DesktopNav.propTypes = {
  style: PropTypes.any,
  navItemsWithDropdowns: PropTypes.any,
  utilityLinks: PropTypes.any,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  isMicrosite: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export default DesktopNav;
