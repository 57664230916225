import React from 'react';
import PropTypes from 'prop-types';

const TwitterLogo = ({ className: customClassName }) => (
  <svg className={customClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
    <mask id="mask">
      <rect fill="white" width="100%" height="100%" />
      <path
        fill="black"
        d="m342.79,273.74l139.58-162.26h-33.08l-121.2,140.88-96.8-140.88h-111.65l146.39,213.04-146.39,170.15h33.08l127.99-148.78,102.23,148.78h111.65l-151.81-220.94h0Zm-45.31,52.66l-14.83-21.21-118.01-168.8h50.81l95.24,136.23,14.83,21.21,123.8,177.08h-50.81l-101.02-144.5h0Z"
      />
    </mask>
    <circle cx="300" cy="300" r="300" fill="currentColor" mask="url(#mask)" />
  </svg>
);

TwitterLogo.propTypes = {
  className: PropTypes.string,
};

export default TwitterLogo;
