import React from 'react';
import PropTypes from 'prop-types';
import styles from './topicTopper.module.scss';
import { BASE_PATHS, className } from 'utils';
import { Link } from 'components';

const TopicTopper = ({ headline }) => (
  <header {...className(styles.topicTopper, 'page-left-inset', 'page-left-inset-topper')}>
    <div className={styles.textWrapper}>
      <div className={styles.row}>
        <Link to={`/${BASE_PATHS.insights}`} className={styles.breadcrumb}>
          Insights
        </Link>
      </div>
      <div className={styles.row}>
        {headline && (
          <h1 className={styles.headline} data-ignore-heading="true">
            {headline}
          </h1>
        )}
      </div>
    </div>
  </header>
);

TopicTopper.propTypes = {
  headline: PropTypes.string.isRequired,
};

export default TopicTopper;
