import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './sidebarStickyMenu.module.scss';
import { className, renderText, toVariableName } from '../../utils';

const Sticky_Top = parseInt(styles.stickyTop);

const SidebarStickyMenu = ({ title, content }) => {
  const [stuck, setStuck] = useState(false);
  const [show, setShow] = useState(true);
  const stickyRef = useRef(null);

  const handleScroll = useCallback(() => {
    const stickyNav = stickyRef?.current;
    if (!stickyNav) {
      return;
    }

    const stickyNavRect = stickyNav.parentNode.getBoundingClientRect();
    setStuck(stickyNavRect.top < Sticky_Top);

    const element = document.getElementById('sidebar-sticky-menu-bottom-edge');
    if (element) {
      const bottomEdgeLineRect = element.getBoundingClientRect();
      const showBottomLine = bottomEdgeLineRect.bottom <= stickyNav.getBoundingClientRect().bottom;
      setShow(!showBottomLine);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <div {...className('component', styles.wrapper)}>
      <div
        data-automation-id={toVariableName(title)}
        ref={stickyRef}
        {...className(styles.stickyNavigation, stuck ? styles.stuck : '', show ? '' : styles.hide)}>
        {title && (
          <h2 className={styles.title} data-ignore-heading="true">
            {title}
          </h2>
        )}
        <div className={styles.container}>{renderText({ json: content })}</div>
      </div>
    </div>
  );
};

SidebarStickyMenu.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
};

export default SidebarStickyMenu;
