import React from 'react';
import PropTypes from 'prop-types';
import ArticleListIntro from '../ArticleList/ArticleListIntro';
import ArticleGridItem from './ArticleGridItem';
import ContentGridItem from './ContentGridItem';
import { className, isNonEmptyArray } from 'utils';
import { articleStubPropTypes } from 'utils/proptypes';
import styles from './articleGrid.module.scss';
import ImageCTAGridItem from '../../ColumnCTA/ImageCTA/ImageCTAGridItem';
import IconCTAGridItem from '../../ColumnCTA/IconCTA/IconCTAGridItem';

const ArticleGrid = ({
  articles,
  withIntro,
  introHeadline,
  introText,
  className: customClassName,
  relatedArticle,
  isMicrosite,
}) => (
  <ul {...className(styles.wrapper, customClassName)}>
    {withIntro && <ArticleListIntro introHeadline={introHeadline} introText={introText} />}
    {isNonEmptyArray(articles) &&
      articles.map((article, i) => {
        if (article.__typename === 'ContentfulColumnCtaItem') {
          return article.ctaType === 'Image' ? (
            <ImageCTAGridItem key={i} {...article} intro={article.intro.intro} />
          ) : (
            <IconCTAGridItem key={i} {...article} intro={article.intro.intro} />
          );
        }
        if (article.__typename === 'ContentfulContentPage') {
          return (
            <ContentGridItem
              key={i}
              article={article}
              relatedArticle={relatedArticle}
              isMicrosite={isMicrosite}
            />
          );
        }
        return <ArticleGridItem key={i} article={article} relatedArticle={relatedArticle} />;
      })}
  </ul>
);

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      node: articleStubPropTypes,
    }),
  ),
  withIntro: PropTypes.bool,
  relatedArticle: PropTypes.bool,
  introHeadline: PropTypes.string,
  introText: PropTypes.object,
  className: PropTypes.string,
  isMicrosite: PropTypes.bool,
};

export default ArticleGrid;
