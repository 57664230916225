import React from 'react';
import PropTypes from 'prop-types';

const HeroPathways = ({ className: customClassName }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 611 263"
    xmlSpace="preserve"
    enableBackground="new 0 0 611 263"
    className={customClassName}>
    <path d="M-0.31 0.45H611V262.99H-0.31z" fill="#f2f2f2" />
    <defs>
      <path id="SVGID_1_" d="M-0.31 3.07H610.9100000000001V262.99H-0.31z" />
    </defs>
    <g clipPath="url(#SVGID_00000105399103636443521970000011986854442674620337_)">
      <path
        className="st2"
        d="M619.4 -191.31L619.4 -267.48 -11.85 165.27 -220.81 165.27 -220.81 171.62 -11.85 171.62z"
      />
      <path
        className="st2"
        d="M-11.85 177.97L-220.81 177.97 -220.81 184.31 -11.85 184.31 908.2 -132.03 619.4 -108.9z"
      />
      <path
        className="st2"
        d="M-220.81 190.66L-220.81 197.01 -11.85 197.01 945.08 86.56 949.55 -26.64 -11.85 190.66z"
      />
      <path
        className="st2"
        d="M950.11 318.12L949.91 205.37 -11.85 203.35 -220.81 203.35 -220.81 209.7 -11.85 209.7z"
      />
      <path
        className="st2"
        d="M-220.81 216.05L-220.81 222.4 -11.85 222.4 619.4 439.64 833.24 413.42 -11.85 216.05z"
      />
    </g>
  </svg>
);

HeroPathways.propTypes = {
  className: PropTypes.string,
};

export default HeroPathways;
