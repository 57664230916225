import React, { forwardRef } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import {
  className,
  createGoogleMapsLink,
  formatPhoneNumber,
  renderText,
  isNonEmptyArray,
} from 'utils';
import {
  Wordmark,
  FacebookLogo,
  InstagramLogo,
  LinkedInLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'svgs';
import { Link, SocialLink } from 'components';
import styles from './footer.module.scss';
import { useArrayRef, useLinks } from 'hooks';
import { useMenuItems } from '../Navigation';

const Location = (footerAddress, i) => {
  const {
    branch,
    firstAddressLine,
    secondAddressLine,
    tollFreeNumber,
    phoneNumber,
    faxNumber,
  } = footerAddress;
  const fullAddress = `${firstAddressLine} ${secondAddressLine}`;

  return (
    <div key={i} className={styles.address}>
      <h1>{branch}</h1>
      <Link className={styles.addressLink} to={createGoogleMapsLink(fullAddress)} newTab>
        {firstAddressLine}, {secondAddressLine}
      </Link>
      {tollFreeNumber && (
        <p>
          <span>Toll-Free </span>
          <a href={`tel:+1${tollFreeNumber}`}>{formatPhoneNumber(tollFreeNumber)}</a>
        </p>
      )}
      {phoneNumber && (
        <p>
          <span>Phone </span>
          <a href={`tel:+1${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
        </p>
      )}
      {faxNumber && (
        <p>
          <span>Fax </span>
          <a href={`tel:+1${faxNumber}`}>{formatPhoneNumber(faxNumber)}</a>
        </p>
      )}
    </div>
  );
};

const Footer = forwardRef(({ className: customClassName, noFocus = false }, ref) => {
  const {
    global: {
      linkedIn,
      twitterHandle,
      facebook,
      youtube,
      instagram,
      footerLinksLeft,
      footerLinksRight,
      footerButtonText,
      footerButtonLink,
      brokerCheckImg,
      brokerCheckText,
      subFooter,
      footerAddresses,
      menu,
      siteContext,
      searchPlaceholder,
      logoLink,
      buttonLink,
      buttonText,
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      global: contentfulGlobal {
        menu {
          heading
          headingUrl
          dropdownHeading
          menuFormatType
          list {
            json
          }
        }
        siteContext
        searchPlaceholder
        linkedIn
        twitterHandle
        facebook
        youtube
        instagram
        footerLinksLeft {
          json
        }
        footerLinksRight {
          json
        }
        footerButtonText
        footerButtonLink
        brokerCheckImg {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
        brokerCheckText {
          json
        }
        subFooter {
          json
        }
        footerAddresses {
          branch
          firstAddressLine
          secondAddressLine
          tollFreeNumber
          phoneNumber
          faxNumber
        }
        logoLink
        buttonLink
        buttonText
      }
    }
  `);
  const footerLeftLinks = useLinks(footerLinksLeft);
  const footerRightLinks = useLinks(footerLinksRight);
  const location = useLocation();
  const navItemsWithSubMenus = menu.map(useMenuItems);
  const isMicrosite = siteContext[0] === 'Micro Site';

  const [menuHeadingsRef, setMenuHeadingsRef] = useArrayRef(); // eslint-disable-line

  const menuMarkup = linkedList =>
    isNonEmptyArray(linkedList) &&
    linkedList.map(({ text, to }, i) =>
      text.join('').trim() && to ? (
        <li key={i} className={styles.menuItem} ref={setMenuHeadingsRef(i)}>
          <Link className={styles.menuLink} to={to} noFocus={noFocus}>
            {text}
          </Link>
        </li>
      ) : null,
    );

  const logoHref = isMicrosite ? logoLink : location.pathname === '/' ? '/#top' : '/';

  return (
    <footer
      {...className(customClassName, styles.footer, isMicrosite && styles.msFooter)}
      ref={ref}>
      <div className="page-left-inset">
        <section className={styles.logoWrap}>
          <Link className={styles.wordmarkLink} to={logoHref}>
            <Wordmark className={styles.wordmark} />
            <span className={styles.visuallyHidden}>Commonwealth Logo</span>
          </Link>
          {isMicrosite && (
            <>
              <search role="search">
                <form
                  onSubmit={event => {
                    event.preventDefault();

                    // Capture and process form data
                    const formData = new FormData(event.target);
                    const searchInput = formData.get('search');

                    // Null value check (empty input)
                    if (!searchInput) {
                      return;
                    }

                    // Route user to search page and populate query string
                    const encodedQueryString = encodeURIComponent(searchInput);
                    navigate(`/search?query=${encodedQueryString}`);
                  }}>
                  <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6081 15.4602L15.4482 16.6201L11.9678 13.1405L13.1277 11.9798L16.6081 15.4602ZM6.76484 11.6985C4.0514 11.6985 1.84323 9.49114 1.84323 6.77687C1.84323 4.06342 4.0514 1.85526 6.76484 1.85526C9.47829 1.85526 11.6865 4.06342 11.6865 6.77687C11.6865 9.49114 9.47829 11.6985 6.76484 11.6985ZM6.76485 0.214722C3.1409 0.214722 0.202698 3.15292 0.202698 6.77687C0.202698 10.4008 3.1409 13.339 6.76485 13.339C10.3888 13.339 13.327 10.4008 13.327 6.77687C13.327 3.15292 10.3888 0.214722 6.76485 0.214722Z" />
                  </svg>
                  <input type="search" name="search" placeholder={searchPlaceholder} />
                </form>
              </search>
              {buttonLink && (
                <Link {...className(styles.contact, styles.headerCta)} to={buttonLink}>
                  {buttonText}
                </Link>
              )}
            </>
          )}
        </section>
        <section className={styles.topfooterWrap}>
          {!isMicrosite && (
            <>
              {navItemsWithSubMenus.map(nav => {
                const { heading, headingUrl, links } = nav;

                return (
                  <ul {...className(styles.navMenuList, styles.menuList)} key={heading}>
                    <li className={styles.menuItem}>
                      <Link className={styles.menuLink} to={headingUrl}>
                        {heading}
                      </Link>
                    </li>
                    {menuMarkup(links)}
                  </ul>
                );
              })}

              <ul {...className(styles.menuListOne, styles.menuList)}>
                {menuMarkup(footerLeftLinks)}
                <li>
                  <a
                    href={footerButtonLink}
                    className={styles.footerButton}
                    target="_blank"
                    rel="noreferrer">
                    {footerButtonText}
                  </a>
                </li>
              </ul>
              {!!footerRightLinks?.length && (
                <ul className={styles.menuList}>{menuMarkup(footerRightLinks)}</ul>
              )}

              {isNonEmptyArray(footerAddresses) && footerAddresses.map(Location)}

              <section className={styles.socialIcons}>
                <ul>
                  {linkedIn && (
                    <SocialLink
                      text="LinkedIn"
                      to={linkedIn}
                      logoSvg={LinkedInLogo}
                      noFocus={noFocus}
                    />
                  )}
                  {twitterHandle && (
                    <SocialLink
                      text="Twitter"
                      to={`https://twitter.com/${twitterHandle}`}
                      logoSvg={TwitterLogo}
                      noFocus={noFocus}
                    />
                  )}
                  {facebook && (
                    <SocialLink
                      text="Facebook"
                      to={facebook}
                      logoSvg={FacebookLogo}
                      noFocus={noFocus}
                    />
                  )}
                  {youtube && (
                    <SocialLink
                      text="Youtube"
                      to={youtube}
                      logoSvg={YoutubeLogo}
                      noFocus={noFocus}
                    />
                  )}
                  {instagram && (
                    <SocialLink
                      text="Instagram"
                      to={instagram}
                      logoSvg={InstagramLogo}
                      noFocus={noFocus}
                    />
                  )}
                </ul>
              </section>
            </>
          )}
          {isMicrosite && (
            <>
              <nav aria-label="Footer navigation.">
                <ul>
                  <li>{renderText(footerLinksLeft)}</li>
                  <li>{renderText(footerLinksRight)}</li>
                </ul>
              </nav>
            </>
          )}
        </section>
      </div>
      <div className={styles.subfooterBackground}>
        <section className={styles.subfooterWrap}>
          <div className="page-left-inset">
            <div className={styles.subfooterInner}>
              <div className={styles.brokerCheck}>
                <a
                  href="https://brokercheck.finra.org/"
                  className={styles.brokerCheckImgLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={brokerCheckImg.file.url}
                    width={brokerCheckImg.file.details.image.width}
                    height={brokerCheckImg.file.details.image.height}
                    alt={brokerCheckImg.description}
                  />
                </a>
                <div>{renderText(brokerCheckText)}</div>
              </div>
              <div>{renderText(subFooter)}</div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  className: PropTypes.string,
  noFocus: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};

Footer.displayName = 'Menu';

export default Footer;
