import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { className, ease } from 'utils';
import { Link } from 'components';
import styles from './mobile.module.scss';
import baseNavStyles from '../navigation.module.scss';
import { Caret } from 'svgs';
import { MENU_TYPE_FORMAT } from 'utils/constants';

const MobileNav = ({
  isMobileMenuOpen,
  navItemsWithDropdowns: mainLinks,
  utilityLinks,
  buttonText,
  buttonLink,
  isMicrosite,
}) => {
  const [mainLinksState, setMainLinks] = useState(mainLinks.map(l => ({ ...l, open: false })));
  const getTransitionDur = useRef(() => {});
  const baseTransitionDur = i => ease.inCubic((i + 1) / mainLinksState.length);

  const collapseMenu = () => {
    setMainLinks(mainLinksState.map(l => ({ ...l, open: false })));
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      getTransitionDur.current = i => ({
        transitionDuration: `${1000 - baseTransitionDur(i) * 400}ms`,
      });
    } else {
      collapseMenu();
      getTransitionDur.current = i => ({
        transitionDuration: `${baseTransitionDur(i) * 400 + 600}ms`,
      });
    }
  }, [isMobileMenuOpen]);

  const openSubLinks = (link, index) => {
    setMainLinks(
      mainLinksState.map((el, i) => {
        if (i === index) {
          el = { ...el, open: !el.open };
        }
        return el;
      }),
    );
  };

  return (
    <nav
      {...className(styles.outerWrapper, !isMobileMenuOpen && styles.outerWrapperClosed)}
      aria-label="Mobile Navigation">
      <div className={styles.innerWrapper}>
        {mainLinksState && (
          <ul className={styles.mainLinksList}>
            {mainLinksState.map((link, i) => (
              <li key={i} className={styles.mainLinkItem} style={getTransitionDur.current(i)}>
                <div
                  {...className(styles.mainLinkTitleContainer, link.open && styles.collapsedLink)}>
                  <Link
                    noFocus={!isMobileMenuOpen}
                    activeClassName={baseNavStyles.activeLink}
                    className={baseNavStyles.link}
                    to={link.headingUrl}
                    partiallyActive>
                    {link.heading}
                  </Link>
                  <button className={styles.iconContainer} onClick={() => openSubLinks(link, i)}>
                    <Caret
                      {...className(
                        styles.arrowDefault,
                        link.open && styles.arrowUp,
                        !link.open && styles.arrowUp && styles.arrowDown,
                      )}
                    />
                  </button>
                </div>
                <div {...className(styles.subLinksContainer, link.open && styles.open)}>
                  {link.dropdownHeading && (
                    <h1 className={styles.dropdownHeading}>{link.dropdownHeading}</h1>
                  )}
                  {link.menuLinks && (
                    <ul data-label={link.heading}>
                      {link.menuLinks.map((l, i) => (
                        <li key={i} className={styles.subLinkItem}>
                          <Link className={styles.subLink} partiallyActive to={l.to}>
                            {link?.menuFormatType === MENU_TYPE_FORMAT.Number && (
                              <span className={styles.prefix}>{`0${i + 1} `}</span>
                            )}
                            {link?.menuFormatType === MENU_TYPE_FORMAT.Hyphen && (
                              <span {...className(styles.prefix, styles.prefixHyphen)}></span>
                            )}
                            <span className={styles.text}>{l.text}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
        {utilityLinks && !isMicrosite && (
          <ul className={styles.utilityLinkList}>
            {utilityLinks.map((item, i) => (
              <li key={i} className={styles.utilityLinkItem}>
                <Link className={baseNavStyles.utilityLink} to={item.to}>
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.actions}>
          {buttonLink && (
            <Link {...className(baseNavStyles.contact, styles.contact)} to={buttonLink}>
              {buttonText}
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

MobileNav.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  isMicrosite: PropTypes.bool,
  navItemsWithDropdowns: PropTypes.any,
  utilityLinks: PropTypes.any,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

export default MobileNav;
