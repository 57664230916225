import React, { useState, useEffect, useRef } from 'react';
import { Caret } from 'svgs';
import { className, renderText, toVariableName } from 'utils';
import PropTypes from 'prop-types';
import styles from './expandCollapse.module.scss';

const ExpandCollapse = ({ title, collapsed, content, expanded }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const contentWrapper = useRef();

  const handleClick = () => {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);
  };

  useEffect(() => {
    expanded && setIsExpanded(true);
  });

  const id = toVariableName(title);

  return (
    <div
      {...className(
        'component-rich-text',
        styles.expandCollapse,
        isExpanded ? styles.expanded : styles.collapsed,
      )}>
      <header
        className={styles.collapsedWrapper}
        onClick={handleClick}
        onKeyPress={handleClick}
        aria-controls={id}
        aria-expanded={isExpanded}
        tabIndex={0}
        role="button">
        <div className={styles.indicatorWrapper}>
          <Caret className={styles.indicator} />
        </div>
        {collapsed && (
          <div className={styles.collapsedContent}>{renderText({ json: collapsed })}</div>
        )}
      </header>
      <div
        id={id}
        className={styles.contentWrapper}
        ref={contentWrapper}
        role="region"
        tabIndex="-1">
        {renderText({ json: content })}
      </div>
    </div>
  );
};

ExpandCollapse.propTypes = {
  title: PropTypes.string,
  collapsed: PropTypes.object.isRequired,
  content: PropTypes.object,
  expanded: PropTypes.bool,
};

export default ExpandCollapse;
