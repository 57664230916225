import React from 'react';
import PropTypes from 'prop-types';
import { articleStubPropTypes } from 'utils/proptypes';
import { authorPortraitParams } from 'utils/imageQueryParams';
import { BASE_PATHS, className, safeGet } from 'utils';
import { Link } from 'components';
import styles from './recircSingle.module.scss';

const RecircSingle = ({ article, floatRight = false, overline }) => {
  const { title, slug, author } = safeGet(article, 'title', 'slug', 'author');
  const { name: authorName, description: authorImageDescription, url: authorImageUrl } = safeGet(
    author,
    'name',
    ['image', 'description'],
    ['image', 'file', 'url'],
  );

  const link = `/${BASE_PATHS.insights}/${slug}`;

  return (
    <section {...className('component', styles.wrapper, floatRight && styles.floatRight)}>
      <h1 className={styles.overline} data-ignore-heading="true">
        {overline}
      </h1>
      <Link to={link} className={styles.link}>
        <p className={styles.title}>{title}</p>
      </Link>
      <figure className={styles.author}>
        <img
          className={styles.portrait}
          src={`${authorImageUrl}${authorPortraitParams}`}
          alt={authorImageDescription || authorName}
        />
        <figcaption className={styles.authorName}>{authorName}</figcaption>
      </figure>
    </section>
  );
};

RecircSingle.propTypes = {
  article: articleStubPropTypes,
  floatRight: PropTypes.bool,
  overline: PropTypes.string,
};

export default RecircSingle;
