import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useStaticQuery, graphql } from 'gatsby';
import { BASE_PATHS, className } from 'utils';
import { carouselItemsPropTypes } from 'utils/proptypes';
import { Carousel } from 'components';
import Slide from '../Slide/index.js';
import TopRightButton from '../TopRightButton';
import styles from './richTextCarousel.module.scss';
import { useLocation } from '@reach/router';

const RichTextCarousel = ({
  title,
  description,
  buttonText,
  link,
  carouselItems,
  name,
  isPathwayCarousel = false,
  isHeroCarousel = false,
}) => {
  const { global } = useStaticQuery(graphql`
    query GlobalRichTextCarouselQuery {
      global: contentfulGlobal {
        siteContext
      }
    }
  `);
  const isMicrosite = global.siteContext[0] === 'Micro Site';

  const location = useLocation();
  const isAdvisorProfile = location.pathname.includes(BASE_PATHS.advisors);

  //filter list to exclude items with links that match the current url, for advisor profile carousels
  const carouselItemsList = isAdvisorProfile
    ? carouselItems.filter(item => location.pathname !== item.link)
    : carouselItems;

  return isEmpty(carouselItems) ? null : (
    <Carousel
      {...className('component', styles.richTextCarousel, isHeroCarousel && styles.isHeroCarousel)}
      title={title}
      description={description}
      name={name}
      topRightButton={
        <TopRightButton
          text={buttonText}
          link={link}
          className={isPathwayCarousel ? styles.light : styles.dark}
        />
      }
      isPathwayCarousel={isPathwayCarousel}
      isHeroCarousel={isHeroCarousel}
      isMicrosite={isMicrosite}>
      {carouselItemsList.map(({ image, video, link, caption, title, subtitle, summary }, i) => (
        <Slide
          key={i}
          image={image}
          video={video}
          link={link}
          caption={caption}
          title={title}
          subtitle={subtitle}
          summary={summary}
          isHeroCarousel={isHeroCarousel}
          isPathwayCarousel={isPathwayCarousel}
        />
      ))}
    </Carousel>
  );
};

RichTextCarousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  carouselItems: carouselItemsPropTypes,
  name: PropTypes.string.isRequired,
  isPathwayCarousel: PropTypes.bool,
  isHeroCarousel: PropTypes.bool,
  isMicrosite: PropTypes.bool,
};

export default RichTextCarousel;
