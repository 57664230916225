import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { className, renderText, toVariableName } from 'utils';

import styles from './textSidebar.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const TextSidebar = ({ headline, content, ...props }) => {
  const ctaContainer = useRef();

  const addDataAutomationTags = domEls => {
    Array.from(domEls).forEach(el => {
      const dataAutomationId = toVariableName(el.textContent);
      return (el.dataset.automationId = dataAutomationId);
    });
  };

  useEffect(() => {
    const currentLinks = ctaContainer.current.querySelectorAll('a');

    addDataAutomationTags(currentLinks);
  }, []);

  return (
    <section
      {...className(
        'component custom-rich-text',
        // ctaBaseStyles.cta,
        styles.floatRight,
        props.className && props.className,
      )}>
      <div {...className(ctaBaseStyles.withBackground, styles.floatRightInner)}>
        <div className={ctaBaseStyles.row}>
          <div className={styles.copy} ref={ctaContainer}>
            {headline && <h1 className={styles.headline}>{headline}</h1>}
            {renderText({ json: content })}
          </div>
        </div>
      </div>
    </section>
  );
};

TextSidebar.propTypes = {
  headline: PropTypes.string,
  content: PropTypes.object,
  className: PropTypes.string,
};

export default TextSidebar;
