import * as React from 'react';
import { Link } from 'gatsby';
import { createBreadcrumbLinks } from 'utils';
import breadcrumbStyles from '../Breadcrumbs/breadcrumbs.module.scss';
import { graphql, useStaticQuery } from 'gatsby';

const Breadcrumbs = (location, isMicrosite = false) => {
  const { allContentfulContentPage } = useStaticQuery(
    graphql`
      query getAllPageTitlesAndSlugs {
        allContentfulContentPage {
          nodes {
            slug
            title
          }
        }
      }
    `,
  );
  if (location === 'undefined') {
    return;
  }
  const crumbs = createBreadcrumbLinks(location, allContentfulContentPage.nodes);
  return crumbs.length > 0 && isMicrosite ? (
    <div>
      <div className={breadcrumbStyles.container}>
        {crumbs.map((crumb, index) =>
          crumbs.length - 1 !== index ? (
            <span key={index} className={breadcrumbStyles.crumbLink}>
              <Link to={crumb.location.toLowerCase()}>{crumb.label}</Link> /
            </span>
          ) : (
            <span key={index} className={breadcrumbStyles.crumbLink}>
              {` ${crumb.label}`}
            </span>
          ),
        )}
      </div>
    </div>
  ) : null;
};

export default Breadcrumbs;
