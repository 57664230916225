/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { className } from 'utils';
import { ArticleGrid } from 'components';
import styles from './columnCTA.module.scss';

const ColumnCTA = ({ title, items, isFormatted }) => {
  const {
    global: { siteContext },
  } = useStaticQuery(graphql`
    query ColumnCTA {
      global: contentfulGlobal {
        siteContext
      }
    }
  `);

  if (!items.length) {
    return null;
  }

  // Check for microsite
  const isMicrosite = siteContext[0] === 'Micro Site';

  const list = isFormatted
    ? items
    : items.map(el => ({
        ...el,
        author: null,
        intro: {
          intro: el?.intro,
        },
        __typename:
          el?.type === 'columnCtaItem'
            ? 'ContentfulColumnCtaItem'
            : el?.type === 'contentPage'
            ? 'ContentfulContentPage'
            : 'ContentfulArticle',
      }));

  return (
    <div
      {...className(
        styles.relatedArticleFooter,
        styles.contentWrapper,
        'component',
        isMicrosite && styles.msContentCta,
      )}>
      {title && <h2>{title}</h2>}
      <ArticleGrid articles={list} className={styles.relatedArticleFooterGrid} relatedArticle />
    </div>
  );
};

ColumnCTA.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  isFormatted: PropTypes.boolean,
};

export default ColumnCTA;
