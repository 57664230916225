import { useEffect, useState } from 'react';
import { mobileWidth } from '../style/abstracts/variables/_layout.scss';

export default function useIsMobile() {
  const isBrowser = () => typeof window !== 'undefined';

  const getIsMobile = () => (isBrowser() ? window.innerWidth < parseInt(mobileWidth) : false);

  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
}
