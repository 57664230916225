/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { className, safeGet, getQueryParams, renderText } from 'utils';
import styles from './bioGridItem.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';
import WideRightArrow from '../../../svgs/Arrow/WideRight';

const BioGridItem = props => {
  const [showModal, setShowModal] = useState(false);
  const {
    url: imageURL,
    description: imageDescription,
    title: imageTitle,
    name,
    intro,
    title,
    itemLinkText,
    bioLinkText,
    bioLinkUrl,
    content,
  } = safeGet(
    props,
    ['photo', 'file', 'url'],
    ['photo', 'title'],
    ['photo', 'description'],
    'name',
    'intro',
    'title',
    'itemLinkText',
    'bioLinkText',
    'bioLinkUrl',
    'content',
  );

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <li {...className(styles.item, styles.itemWithImage)}>
      <div className={styles.itemInner} onClick={() => setShowModal(true)}>
        <div className={styles.featuredImgWrapper}>
          <div className={styles.clippingBox}>
            <img
              className={styles.featuredImg}
              src={`${imageURL}${getQueryParams({ w: 400, h: 400, fit: 'fill' })}`}
              alt={imageDescription || imageTitle}
            />
          </div>
        </div>
        <div className={styles.text}>
          <h1 className={styles.name} data-ignore-heading="true">
            {name}
          </h1>
          <p className={styles.title}>{title}</p>
          <p className={styles.intro}>{intro}</p>
          <button {...className(styles.textLink)}>
            <span className={styles.buttonLink}>{itemLinkText}</span>
            <WideRightArrow />
          </button>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={{
          overlay: { zIndex: 100 },
          content: {
            right: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            maxWidth: 1300,
            padding: 0,
            left: 'unset',
          },
        }}>
        <div className={styles.modalContent}>
          <div className={styles.leftCol}>
            <img
              className={styles.bioPhoto}
              src={`${imageURL}${getQueryParams({ w: 350, h: 425, fit: 'fill' })}`}
              alt={imageDescription || imageTitle}
            />
            <h1 className={styles.name} data-ignore-heading="true">
              {name}
            </h1>
            <p className={styles.title}>{title}</p>
          </div>
          <div className={styles.rightCol}>
            <button className={styles.close} onClick={closeModal}>
              <i className="fa-sharp fa-regular fa-xmark fa-lg"></i>
              Close
            </button>
            <div {...className('custom-rich-text', styles.bioContent)}>
              {content && renderText({ json: content })}
            </div>
            {bioLinkUrl && bioLinkText && (
              <Link to={bioLinkUrl} {...className('button-link', styles.textLink)}>
                <span className={styles.buttonLink}>{bioLinkText}</span>
                <WideRightArrow />
              </Link>
            )}
          </div>
        </div>
      </Modal>
    </li>
  );
};

BioGridItem.propTypes = {
  props: PropTypes.object,
};

export default BioGridItem;
