import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray, renderText, showTrailingPeriod } from 'utils';
// import { articleStubPropTypes } from 'utils/proptypes';
import { Link, Paginate } from 'components';
import styles from './appearancesIndex.module.scss';

const AppearancesIndex = ({
  appearances,
  numPages,
  currentPage,
  basePath,
  className: customClassName,
}) => (
  <div className={'page-left-inset page-left-inset-content body-content'}>
    <section {...className(customClassName, styles.outerWrapper)}>
      <div className={styles.innerWrapper}>
        <ul
          {...className(styles.appearancesList, numPages < 2 && 'no-pagination', customClassName)}>
          {isNonEmptyArray(appearances) &&
            appearances.map((appearance, i) => (
              <li key={i} className={styles.item}>
                <Link to={appearance.link}>{appearance.headline.trim()}</Link>
                {showTrailingPeriod(appearance.headline)}&nbsp;
                {appearance.description && renderText(appearance.description)}
                <br />
                {appearance.date}
              </li>
            ))}
        </ul>
        {numPages > 1 && (
          <Paginate
            currentPage={currentPage}
            numPages={numPages}
            basePath={basePath}
            className={styles.pagination}
          />
        )}
      </div>
    </section>
  </div>
);

AppearancesIndex.propTypes = {
  appearances: PropTypes.object,
  basePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
};

export default AppearancesIndex;
