import React from 'react';

const DoubleUpArrow = props => (
  <svg viewBox="0 0 1000 1000" width={28} height={28} fill="#304A3D" {...props}>
    <path d="M252.98 296.51L10 539.48l30.37 30.37 30.37 30.37 213.41-213.41L497.98 173 711.8 386.82l213.41 213.41 32.4-29.97 32.4-30.37-242.98-242.98C613.39 162.87 502.43 53.53 500 53.53c-2.43 0-113.39 109.34-247.02 242.98z" />
    <path d="M252.98 640.72L10 883.7l31.59 31.18 31.18 31.59 213.82-213.82L500 519.24l213.41 213.41 213.82 213.82 30.37-30.37 30.37-30.37-243.78-243.79c-134.45-134.45-245-244.19-246.21-244.19-1.22 0-111.37 109.34-245 242.97z" />
  </svg>
);

export default DoubleUpArrow;
