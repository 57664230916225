import React from 'react';
import { className, safeGet } from 'utils';
import styles from './iconCTAGridItem.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';

const IconCTAGridItem = props => {
  const {
    url: imageURL,
    description: imageDescription,
    title: imageTitle,
    intro,
    ctaUrl,
  } = safeGet(
    props,
    ['image', 'file', 'url'],
    ['image', 'title'],
    ['image', 'description'],
    'intro',
    'ctaUrl',
  );

  if (!intro) {
    return null;
  }

  return (
    <li {...className(styles.item, styles.itemWithImage)}>
      <div className={styles.itemInner}>
        <div className={styles.featuredImgWrapper}>
          {ctaUrl ? (
            <Link to={ctaUrl}>
              <img
                className={styles.featuredImg}
                src={imageURL}
                alt={imageDescription || imageTitle}
              />
            </Link>
          ) : (
            <img
              className={styles.featuredImg}
              src={imageURL}
              alt={imageDescription || imageTitle}
            />
          )}
        </div>
        <div className={styles.text}>
          <Link to={ctaUrl}>
            <p className={styles.intro}>{intro}</p>
          </Link>
        </div>
      </div>
    </li>
  );
};

IconCTAGridItem.propTypes = {
  props: PropTypes.object,
};

export default IconCTAGridItem;
