import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';

import styles from './slideShare.module.scss';

const SlideShare = ({ slideShareKey, ...props }) => (
  <div
    {...className(styles.slideShare, props.className && props.className)}
    data-automation-id={`slideshare-${slideShareKey}`}>
    <iframe
      className="component"
      title={`SlideShare-${slideShareKey}`}
      src={`//www.slideshare.net/slideshow/embed_code/key/${slideShareKey}`}
      width="100%"
      frameBorder="0"
      marginWidth="0"
      marginHeight="0"
      scrolling="no"
      allowFullScreen
    />
  </div>
);

SlideShare.propTypes = {
  slideShareKey: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SlideShare;
