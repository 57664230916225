import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import styles from './timeline.module.scss';
import TimelineItem from './TimelineItem';

const Timeline = props => {
  const { items } = props;

  return (
    <div {...className(styles.wrapper)}>
      <table>
        <tbody>
          {isNonEmptyArray(items) &&
            items.map((data, i) => (
              <tr key={i}>
                <td>{i % 2 === 0 ? <TimelineItem {...data} /> : null}</td>
                <td>{i % 2 === 1 ? <TimelineItem {...data} /> : null}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Timeline.propTypes = {
  items: PropTypes.array,
};

export default Timeline;
