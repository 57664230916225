import React from 'react';
import PropTypes from 'prop-types';
import { safeGet, className, getQueryParams, isNonEmptyArray } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { Link } from 'components';

import styles from './fullWidth.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const FullWidth = ({ headline, buttonText, buttonLink, buttons, image, type, ...props }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    image,
    'title',
    'description',
    ['file', 'url'],
  );

  //check if there's only one button
  const buttonCount = buttons && buttons.length === 1;
  const relatedLinks = type.includes('Related Links');
  return (
    <section
      {...className(
        'component page-left-inset',
        ctaBaseStyles.cta,
        ctaBaseStyles.withBackground,
        props.className && props.className,
      )}>
      <div className={ctaBaseStyles.row}>
        <div className={styles.copy}>
          {imageUrl && (
            <img
              className={styles.image}
              src={`${imageUrl}${getQueryParams({
                w: 256,
                h: 256,
                fit: 'fill',
                f: 'face',
              })}`}
              alt={imageDescription || imageTitle}
            />
          )}
          {headline && (
            <h1
              className={relatedLinks ? styles.headlineRelated : styles.headline}
              data-ignore-heading="true">
              {headline}
            </h1>
          )}
        </div>
        {buttonText && buttonLink && (
          <div className={styles.buttonWrapper}>
            <Link
              {...className(styles.button, relatedLinks && styles.buttonRelated)}
              to={buttonLink}
              newTab>
              {buttonText}
            </Link>
          </div>
        )}
        {buttons && isNonEmptyArray(buttons) && (
          <div className={buttonCount ? styles.buttonWrapper : styles.buttonWrapperFull}>
            {buttons.map((item, i) => (
              <Link
                {...className(styles.button, relatedLinks && styles.buttonRelated)}
                to={item[1]}
                key={i}
                newTab>
                {item[0]}
              </Link>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

FullWidth.propTypes = {
  headline: PropTypes.string,
  buttons: PropTypes.array,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  image: imagePropTypes,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default FullWidth;
