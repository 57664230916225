import React from 'react';
import { articleStubPropTypes, topicPropTypes } from 'utils/proptypes';
import { BASE_PATHS, className, isNonEmptyArray, safeGet, getQueryParams } from 'utils';
import { Link } from 'components';
import getRichTextReadingTime from 'utils/getRichTextReadingTime';
import styles from './contentGridItem.module.scss';
import PropTypes from 'prop-types';

const TopicLink = ({ topic }) => (
  <span className={styles.topic}>
    <Link to={`/${BASE_PATHS.insights}/${topic.slug}`} className={styles.topicLink}>
      {topic.name}
    </Link>
  </span>
);

TopicLink.propTypes = {
  topic: topicPropTypes,
};

const ContentGridItem = ({ article, relatedArticle }, isMicrosite) => {
  const {
    url: articleImageURL,
    description: articleImageDescription,
    title: articleImageTitle,
    author,
    topic,
    content,
    thumbnailImage,
    featuredImage,
  } = safeGet(
    article,
    ['articleImage', 'file', 'url'],
    ['articleImage', 'title'],
    ['articleImage', 'description'],
    'thumbnailImage',
    'author',
    { query: ['topics', 0], name: 'topic' },
    'content',
    'featuredImage',
  );

  const { name: authorName, url: authorImageURL, description: authorImageDescription } = safeGet(
    author,
    'name',
    ['image', 'file', 'url'],
    ['image', 'description'],
  );

  const {
    title: thumbnailTitle,
    description: thumbnailDescription,
    url: thumbnailImageUrl,
  } = safeGet(thumbnailImage, ['title'], ['description'], ['file', 'url']);

  const {
    title: featuredImageTitle,
    description: featuredImageDescription,
    url: featuredImageImageUrl,
  } = safeGet(featuredImage, ['title'], ['description'], ['file', 'url']);

  const { title: topicTitle, description: topicDescription, url: topicImageUrl } = safeGet(
    topic,
    ['image', 'title'],
    ['image', 'description'],
    ['image', 'file', 'url'],
  );

  const hasImage = articleImageURL || topicImageUrl || thumbnailImage || featuredImageImageUrl;
  const readingTime = getRichTextReadingTime(content);

  const getImage = () => {
    if (featuredImageImageUrl) {
      return { url: featuredImageImageUrl, alt: featuredImageDescription || featuredImageTitle };
    }
    if (thumbnailImageUrl) {
      return { url: thumbnailImageUrl, alt: thumbnailDescription || thumbnailTitle };
    }
    const url = articleImageURL ? articleImageURL : topicImageUrl;
    const alt = articleImageURL
      ? articleImageDescription || articleImageTitle
      : topicDescription || topicTitle;
    return { url, alt };
  };

  return (
    <li
      {...className(
        styles.item,
        hasImage && styles.itemWithImage,
        isMicrosite && styles.isMicrosite,
      )}>
      <div className={styles.itemInner}>
        {hasImage && (
          <Link to={`/${BASE_PATHS.insights}/${article.slug}`} className={styles.featuredImgLink}>
            <div {...className(styles.clippingBox, relatedArticle && styles.relatedArticle)}>
              <img
                className={styles.featuredImg}
                src={`${getImage().url}${getQueryParams({
                  w: relatedArticle ? 400 : 500,
                  h: relatedArticle ? 275 : 300,
                  fit: 'fill',
                })}`}
                alt={getImage().alt}
              />
            </div>
          </Link>
        )}
        <div className={styles.text}>
          <h3 className={styles.title}>
            <Link to={`/${BASE_PATHS.insights}/${article.slug}`} className={styles.titleLink}>
              {article.title}
            </Link>
          </h3>
          {article.intro && <p className={styles.intro}>{article.intro.intro}</p>}
          {isNonEmptyArray(article.topics) && (
            <div className={styles.topicList}>
              {' '}
              {article.topics.map((topic, index) => (
                <TopicLink key={`topic-${index}`} topic={topic} />
              ))}
            </div>
          )}
        </div>
        {article.author && (
          <div className={styles.authorWrapper}>
            <div className={styles.authorLockup}>
              {authorImageURL && (
                <img
                  className={styles.authorImg}
                  src={authorImageURL}
                  alt={authorImageDescription || authorName}
                />
              )}
              <div className={styles.authorNameDate}>
                {article.author.name && <p className={styles.authorName}>{article.author.name}</p>}
                {(article.date || readingTime) && (
                  <p className={styles.publishDate}>
                    {article.date && <span>{article.date}</span>}
                    {readingTime && (
                      <span className={styles.readingTime}>
                        Estimated Reading Time: {Math.ceil(readingTime.minutes)} Minutes
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

ContentGridItem.propTypes = {
  article: articleStubPropTypes,
  relatedArticle: PropTypes.bool,
  isMicrosite: PropTypes.bool,
};

export default ContentGridItem;
