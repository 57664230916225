import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connectHits, connectPagination, Highlight, Snippet } from 'react-instantsearch-dom';
import get from 'lodash.get';
import tabbable from 'tabbable';
import { Paginate, Link } from 'components';
import { className } from 'utils';
import styles from './searchResults.module.scss';

const AdvisorHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.advisor)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>Advisor Profile</p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="headline" hit={hit} tagName="mark" />
        </h2>
        <div className={styles.advisorDetailsWrapper}>
          {hit.featuredImage?.src && (
            <figure className={styles.advisorPortraitWrapper}>
              <img src={hit.featuredImage.src} alt={hit.featuredImage.alt} />
            </figure>
          )}
          <p className={styles.advisorName}>{hit.title}</p>
        </div>
      </Link>
    </article>
  </li>
);

AdvisorHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    featuredImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    headline: PropTypes.string,
    title: PropTypes.string,
  }),
};

const PageTypePath = hit => {
  const pathResults = hit.path.split('/');
  const pagePaths = { branches: pathResults, depth: pathResults.length || 0 };
  const pageTopBranch =
    pagePaths.depth >= 2
      ? pagePaths.branches[0] === ''
        ? pagePaths.branches[1].replace(/-/g, ' ')
        : pagePaths.branches[0].replace('-', ' ')
      : pagePaths.branches[0].replace(/-/g, ' '); // top-level page types are themselves
  return pageTopBranch;
};

const PageHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.page)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>{PageTypePath(hit)}</p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h2>
        {hit.intro ? (
          <p className={styles.teaseText}>
            <Snippet attribute="intro" hit={hit} tagName="mark" />
          </p>
        ) : (
          hit.content && (
            <p className={styles.teaseText}>
              <Snippet attribute="content" hit={hit} tagName="mark" />
            </p>
          )
        )}
      </Link>
    </article>
  </li>
);

PageHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    content: PropTypes.string,
  }),
};

const LandingPageHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.page)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>{PageTypePath(hit)}</p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h2>
        {hit.intro ? (
          <p className={styles.teaseText}>
            <Snippet attribute="intro" hit={hit} tagName="mark" />
          </p>
        ) : (
          hit.content && (
            <p className={styles.teaseText}>
              <Snippet attribute="content" hit={hit} tagName="mark" />
            </p>
          )
        )}
      </Link>
    </article>
  </li>
);

LandingPageHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    content: PropTypes.string,
  }),
};

const TopicHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.page)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>Topic</p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h2>
        <p className={styles.teaseText}>Check out all our insights on this topic.</p>
      </Link>
    </article>
  </li>
);

TopicHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    content: PropTypes.string,
  }),
};

const ArticleHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.article)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>{hit.topics[0]} Insight</p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h2>
        {hit.intro && (
          <p className={styles.teaseText}>
            <Snippet attribute="intro" hit={hit} tagName="mark" />
          </p>
        )}
      </Link>
    </article>
  </li>
);

ArticleHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    content: PropTypes.string,
    topics: PropTypes.arrayOf(PropTypes.string),
  }),
};

const ContentPageHit = ({ hit }) => (
  <li className={styles.resultsListItem}>
    <article {...className(styles.resultContent, styles.article)}>
      <Link to={`${hit.path}`} className={styles.resultLink}>
        <p className={styles.resultOverline}>
          {hit.topics.map((topic, key) => (
            <>
              <span key={key}>{topic}</span>
              {key < hit.topics.length - 1 && <>,&nbsp;</>}
            </>
          ))}
        </p>
        <h2 className={styles.resultTitle}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h2>
        {hit.intro && (
          <p className={styles.teaseText}>
            <Snippet attribute="intro" hit={hit} tagName="mark" />
          </p>
        )}
      </Link>
    </article>
  </li>
);

ContentPageHit.propTypes = {
  hit: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    content: PropTypes.string,
    topics: PropTypes.arrayOf(PropTypes.string),
  }),
};

const NoResults = () => (
  <section {...className(styles.resultsList, styles.noResultsWrapper)}>
    <article className={styles.suggestionsBox}>
      <h2 className={styles.suggestionsTitle}>
        Can't find what you're looking for? Try one of these:
      </h2>
      <ul className={styles.suggestionsList}>
        <li className={styles.suggestionsListItem}>
          <Link className={styles.suggestionLink} to="/our-insights">
            Our Insights
          </Link>
        </li>
        <li className={styles.suggestionsListItem}>
          <Link className={styles.suggestionLink} to="/what-we-offer">
            What We Offer
          </Link>
        </li>
        <li className={styles.suggestionsListItem}>
          <Link className={styles.suggestionLink} to="/">
            Home
          </Link>
        </li>
      </ul>
    </article>
  </section>
);

const Hits = ({ hits, searchHitsRef }) => {
  const hitMapping = {
    ['advisorProfilePage']: AdvisorHit,
    ['page']: PageHit,
    ['contact']: PageHit,
    ['whatWeOffer']: PageHit,
    ['insightsLanding']: PageHit,
    ['topic']: TopicHit,
    ['article']: ArticleHit,
    ['landingPage']: LandingPageHit,
    ['contentPage']: ContentPageHit,
  };

  return (
    <>
      {hits.length > 0 ? (
        <ul ref={searchHitsRef} className={styles.resultsList}>
          {hits.map((hit, i) => {
            const _component = hitMapping[hit.contentful_type];
            return _component && <_component key={`${hit.objectID}${i}`} hit={hit} />;
          })}
        </ul>
      ) : (
        <NoResults />
      )}
    </>
  );
};

Hits.propTypes = {
  hits: PropTypes.array,
  searchHitsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

const Pagination = ({ currentRefinement, nbPages, refine, scrollTop, searchHitsRef }) => {
  const hits = searchHitsRef.current;
  const scrollOptions = { top: scrollTop, behavior: 'smooth' };

  const focusFirstHit = () => {
    if (hits) {
      const tabbableHits = get(tabbable(hits), 0);
      typeof tabbableHits !== 'undefined' && tabbableHits.focus({ preventScroll: true });
    }
  };

  const onPaginatePrev = e => {
    refine(currentRefinement - 1);
    focusFirstHit();
    window && window.scrollTo(scrollOptions);

    e.preventDefault();
  };

  const onPaginateNext = e => {
    refine(currentRefinement + 1);
    focusFirstHit();
    window && window.scrollTo(scrollOptions);

    e.preventDefault();
  };

  return (
    <div {...className(styles.paginationWrapper, nbPages < 1 && styles.disabled)}>
      <Paginate
        currentPage={currentRefinement}
        numPages={nbPages > 0 ? nbPages : 1}
        basePath="/"
        className={styles.pagination}
        onPaginate={refine}
        onPaginatePrev={onPaginatePrev}
        onPaginateNext={onPaginateNext}
      />
    </div>
  );
};

Pagination.propTypes = {
  currentRefinement: PropTypes.number,
  nbPages: PropTypes.number,
  refine: PropTypes.func,
  scrollTop: PropTypes.number,
  searchHitsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

const CustomHits = connectHits(Hits);
const CustomPagination = connectPagination(Pagination);

const SearchResults = () => {
  const wrapperRef = useRef(null);
  const [wrapperTop, setWrapperTop] = useState(0);
  const searchHitsRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current) {
      setWrapperTop(wrapperRef.current.getBoundingClientRect().top);
    }
  }, [wrapperRef.current]);

  return (
    <section {...className(styles.resultsWrapper, 'page-left-inset')} ref={wrapperRef}>
      <CustomPagination scrollTop={wrapperTop} searchHitsRef={searchHitsRef} />
      <CustomPagination scrollTop={wrapperTop} searchHitsRef={searchHitsRef} />
      <CustomHits searchHitsRef={searchHitsRef} />
    </section>
  );
};

export default SearchResults;
