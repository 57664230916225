import * as THREE from 'three';
import Cover from './canvas-cover';
import { TweenMax, Power1, Power2, Power4 } from 'gsap';
import { CANVAS_ANIMATION_DURATION } from 'utils/constants';

export default class Canvas {
  constructor() {
    this.renderer;
    this.scene;
    this.camera;
    this.camPos = new THREE.Vector3();
    this.camRot = new THREE.Vector3();

    this.scrollPos = 0;

    this.perspective = window.innerWidth / 2;
    this.fov = (180 * (2 * Math.atan(window.innerHeight / 2 / this.perspective))) / Math.PI;

    this.cover;

    this.pos = new THREE.Vector2();
    this.tar = new THREE.Vector2();

    this.MOBILE_BREAKPOINT = 850;

    if (!('ontouchstart' in document)) {
      document.addEventListener('mousemove', e => this.mouse(e));
    }
  }

  init() {
    return new Promise(resolve => {
      //renderer
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.setClearColor(0xffffff, 0);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);

      //camera
      this.camera = new THREE.PerspectiveCamera(
        this.fov,
        window.innerWidth / window.innerHeight,
        0.01,
        this.perspective * 2 + 1,
      );
      this.camera.position.z = this.perspective;
      this.camera.position.y = window.innerHeight / -6;
      this.camera.rotation.z = -5 * THREE.Math.DEG2RAD;
      this.camera.rotation.x = 15 * THREE.Math.DEG2RAD;
      this.camera.rotation.y = -5 * THREE.Math.DEG2RAD;
      this.camera.aspect = window.innerWidth / window.innerHeight;

      //scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      //resize event
      window.addEventListener('resize', () => this.resize());

      //append our canvas DOM to the HTML canvas container
      document.getElementById('canvas')?.appendChild(this.renderer.domElement);

      //the main element
      this.cover = new Cover();

      //add the mesh
      this.cover.init().forEach(e => {
        this.scene.add(e.mesh);
      });

      //resolve the promise
      resolve();
    });
  }

  in() {
    return new Promise(resolve => {
      TweenMax.to('#canvas', { height: '100%' });
      TweenMax.fromTo(
        this.camRot,
        CANVAS_ANIMATION_DURATION,
        { y: 25 * THREE.Math.DEG2RAD, x: -20 * THREE.Math.DEG2RAD, z: -25 * THREE.Math.DEG2RAD },
        {
          y: -5 * THREE.Math.DEG2RAD,
          x: 15 * THREE.Math.DEG2RAD,
          z: -5 * THREE.Math.DEG2RAD,
          ease: Power1.easeOut,
        },
      );
      TweenMax.fromTo(
        this.camPos,
        CANVAS_ANIMATION_DURATION,
        { y: window.innerHeight / 2 },
        { y: window.innerHeight / -6, ease: Power1.easeOut, onComplete: resolve },
      );
      this.cover.obj.forEach(e => e.in());
    });
  }

  inL() {
    TweenMax.fromTo(
      this.camRot,
      CANVAS_ANIMATION_DURATION,
      { y: 45 * THREE.Math.DEG2RAD, x: -10 * THREE.Math.DEG2RAD, z: -35 * THREE.Math.DEG2RAD },
      {
        y: -5 * THREE.Math.DEG2RAD,
        x: 15 * THREE.Math.DEG2RAD,
        z: -5 * THREE.Math.DEG2RAD,
        ease: Power1.easeOut,
      },
    );
    TweenMax.fromTo(
      this.camPos,
      CANVAS_ANIMATION_DURATION,
      { y: window.innerHeight / 2, x: window.innerWidth / -4 },
      { y: window.innerHeight / -6, x: 0, ease: Power1.easeOut },
    );
    this.cover.obj.forEach(e => e.inL());
  }

  out() {
    return new Promise(resolve => {
      TweenMax.to(this.camRot, 1, {
        y: -30 * THREE.Math.DEG2RAD,
        x: 10 * THREE.Math.DEG2RAD,
        z: 10 * THREE.Math.DEG2RAD,
        ease: Power2.easeIn,
      });
      TweenMax.to(this.camPos, CANVAS_ANIMATION_DURATION, {
        y: window.innerHeight / -4,
        ease: Power2.easeIn,
      });
      TweenMax.to('#canvas', CANVAS_ANIMATION_DURATION, {
        height: 0,
        ease: Power4.easeIn,
        onComplete: resolve,
      });
      this.cover.obj.forEach(e => e.out());
    });
  }

  mouse(e) {
    this.tar.x = e.clientX - window.innerWidth / 2;
    this.tar.y = e.clientY - window.innerHeight / 2;
  }

  resize() {
    if (!this.camera || !this.renderer) {
      return;
    }

    if (window.innerWidth < this.MOBILE_BREAKPOINT) {
      return;
    }

    this.perspective = window.innerWidth / 2;
    this.fov = (180 * (2 * Math.atan(window.innerHeight / 2 / this.perspective))) / Math.PI;
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.fov = this.fov;
    this.camera.far = this.perspective * 2 + 1;
    this.camera.position.z = this.perspective;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    if (this.cover) {
      this.cover.resize();
    }
  }

  rAF() {
    this.scrollPos = window.scrollY;

    if (this.renderer) {
      //cursor position
      this.pos.x =
        Math.abs(this.tar.x - this.pos.x) > 0.05
          ? (this.tar.x - this.pos.x) * 0.05 + this.pos.x
          : this.tar.x;
      this.pos.y =
        Math.abs(this.tar.y - this.pos.y) > 0.05
          ? (this.tar.y - this.pos.y) * 0.05 + this.pos.y
          : this.tar.y;

      this.camera.position.x = this.camPos.x;

      this.camera.rotation.set(
        this.camRot.x,
        this.camRot.y - (this.pos.x / window.innerWidth) * 2 * THREE.Math.DEG2RAD,
        this.camRot.z,
      );

      this.camera.position.y = this.camPos.y - (this.pos.y / window.innerHeight) * 20;

      // don't rotate stripes on mobile
      if (window.innerWidth < this.MOBILE_BREAKPOINT) {
        this.cover.rAF(this.pos.x, this.pos.y, 0);
      } else {
        this.cover.rAF(this.pos.x, this.pos.y, this.scrollPos);
      }

      //then render them out
      this.renderer.render(this.scene, this.camera);
    }
  }
}
