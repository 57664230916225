import React from 'react';
import { articleStubPropTypes, topicPropTypes } from 'utils/proptypes';
import { BASE_PATHS, className, isNonEmptyArray, safeGet, getQueryParams } from 'utils';
import { Link } from 'components';
import getRichTextReadingTime from 'utils/getRichTextReadingTime';
import styles from './articleListItem.module.scss';

const TopicLink = ({ topic }) => (
  <span className={styles.topic}>
    <Link to={`/${BASE_PATHS.insights}/${topic.slug}`} className={styles.topicLink}>
      {topic.name}
    </Link>
  </span>
);

TopicLink.propTypes = {
  topic: topicPropTypes,
};

const ArticleListItem = ({ article }) => {
  const {
    url: articleImageURL,
    description: articleImageDescription,
    title: articleImageTitle,
    author,
    topic,
    content,
  } = safeGet(
    article,
    ['articleImage', 'file', 'url'],
    ['articleImage', 'title'],
    ['articleImage', 'description'],
    'author',
    { query: ['topics', 0], name: 'topic' },
    'content',
  );

  const { name: authorName, url: authorImageURL, description: authorImageDescription } = safeGet(
    author,
    'name',
    ['image', 'file', 'url'],
    ['image', 'description'],
  );

  const { title: topicTitle, description: topicDescription, url: topicImageUrl } = safeGet(
    topic,
    ['image', 'title'],
    ['image', 'description'],
    ['image', 'file', 'url'],
  );

  const hasImage = articleImageURL || topicImageUrl;
  const readingTime = getRichTextReadingTime(content);

  return (
    <li {...className(styles.item, hasImage && styles.itemWithImage)}>
      {hasImage && (
        <Link to={`/${BASE_PATHS.insights}/${article.slug}`} className={styles.featuredImgLink}>
          {articleImageURL ? (
            <img
              className={styles.featuredImg}
              src={`${articleImageURL}${getQueryParams({ w: 180, h: 200, fit: 'fill' })}`}
              alt={articleImageDescription || articleImageTitle}
            />
          ) : (
            <img
              className={styles.featuredImg}
              src={`${topicImageUrl}${getQueryParams({ w: 180, h: 200, fit: 'fill' })}`}
              alt={topicDescription || topicTitle}
            />
          )}
        </Link>
      )}
      <div className={styles.text}>
        {isNonEmptyArray(article.topics) && <TopicLink topic={article.topics[0]} />}
        <h1 className={styles.title} data-ignore-heading="true">
          <Link to={`/${BASE_PATHS.insights}/${article.slug}`} className={styles.titleLink}>
            {article.title}
          </Link>
        </h1>
        {article.intro && <p className={styles.intro}>{article.intro.intro}</p>}
        {article.author && (
          <div className={styles.authorLockup}>
            {authorImageURL && (
              <img
                className={styles.authorImg}
                src={authorImageURL}
                alt={authorImageDescription || authorName}
              />
            )}
            <div className={styles.authorNameDate}>
              {article.author.name && <p className={styles.authorName}>{article.author.name}</p>}
              <div className={styles.dateReadingTime}>
                {article.date && <p className={styles.publishDate}>{article.date}</p>}
                {readingTime && (
                  <p className={styles.readingTime}>
                    | Estimated Reading Time: {Math.ceil(readingTime.minutes)} Minutes
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

ArticleListItem.propTypes = {
  article: articleStubPropTypes,
};

export default ArticleListItem;
