import React from 'react';
import { className, safeGet } from 'utils';
import styles from './focusCTAGridItem.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';

const FocusCTAGridItem = props => {
  const { headline, intro, buttonText, buttonLink } = safeGet(
    props,
    'headline',
    'intro',
    'buttonText',
    'buttonLink',
  );

  return (
    <li {...className(styles.item, styles.itemWithImage)}>
      <div className={styles.itemInner}>
        <div className={styles.text}>
          <h3 className={styles.title}>{headline}</h3>
          {intro && <p className={styles.intro}>{intro}</p>}
          <Link {...className(styles.button)} to={buttonLink}>
            {buttonText}
          </Link>
        </div>
      </div>
    </li>
  );
};

FocusCTAGridItem.propTypes = {
  props: PropTypes.object,
};

export default FocusCTAGridItem;
