import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { menuPropTypes } from 'utils/proptypes';
import { className, renderText } from 'utils';
import Navigation from '../Page/Navigation';
import styles from './bodyContent.module.scss';
import basicPageStyles from '../Page/BasicPageTopper/basicPageTopper.module.scss';
import { Breadcrumbs } from 'components';

const BodyContent = ({
  children,
  content,
  className: customClassName,
  menu,
  isMicrosite = false,
  showBreadcrumbs = false,
  breadcrumbsLocation,
}) => {
  const checkTables = () => {
    document.querySelectorAll('.body-content table').forEach(table => {
      if (table.parentElement.clientWidth < table.clientWidth) {
        table.parentElement.parentElement.setAttribute('data-is-overflow', 'true');
      } else {
        table.parentElement.parentElement.setAttribute('data-is-overflow', 'false');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('resize', checkTables);

    return () => {
      window.removeEventListener('resize', checkTables);
    };
  }, []);

  useEffect(() => {
    checkTables();
  }, []);

  return (
    <Fragment>
      <div className="page-left-inset page-left-inset-content body-content">
        <section
          {...className(
            customClassName,
            styles.corePageContent,
            styles.basicPageBody,
            isMicrosite && styles.isMicrosite,
          )}>
          {showBreadcrumbs && (
            <Breadcrumbs location={breadcrumbsLocation} isMicrosite={isMicrosite} />
          )}
          {children}
          {content && renderText(content)}
        </section>
      </div>
      {menu && <Navigation className={basicPageStyles.pagesNavigationFooter} menu={menu} />}
    </Fragment>
  );
};

BodyContent.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  className: PropTypes.string,
  overline: PropTypes.string,
  menu: menuPropTypes,
  location: PropTypes.object,
  isMicrosite: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool,
  breadcrumbsLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default BodyContent;
