import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import styles from './focusCTA.module.scss';
import FocusCTAGridItem from './FocusCTAGridItem';

const FocusCTAs = props => {
  const { heading, keyFocusCtAs } = props;

  return (
    <div {...className('component', styles.outer_wrapper)}>
      <h2>{heading}</h2>
      <ul {...className(styles.wrapper)}>
        {isNonEmptyArray(keyFocusCtAs) &&
          keyFocusCtAs.map((data, i) => <FocusCTAGridItem key={i} {...data} />)}
      </ul>
    </div>
  );
};

FocusCTAs.propTypes = {
  heading: PropTypes.string,
  keyFocusCtAs: PropTypes.array,
};

export default FocusCTAs;
