import React, { useState, useEffect } from 'react';
import { INLINES } from '@contentful/rich-text-types';
import { className, renderText } from 'utils';
import PropTypes from 'prop-types';

import { Link } from 'components';
import { Close } from 'svgs';
import { COOKIE_POLICY_KEY, MONTH_IN_MILLIS } from 'utils/constants';
import styles from './cookieConsent.module.scss';

const getLastShown = () => {
  const date = window.localStorage.getItem(COOKIE_POLICY_KEY);
  return new Date(date ? parseInt(date) : null);
};

/**
 * Check that time difference between last shown
 * and today is more than a month.
 */
const shouldShowPolicy = date => {
  const now = new Date();
  const timeDifference = now.getTime() - date.getTime();

  return timeDifference >= MONTH_IN_MILLIS;
};

const CookieConsent = ({ className: customClassName, headline, intro }) => {
  const [showPolicy, setShowPolicy] = useState(false);

  useEffect(() => {
    const lastShown = getLastShown();
    setShowPolicy(shouldShowPolicy(lastShown));
  }, []);

  const onAccept = () => {
    window.localStorage.setItem(COOKIE_POLICY_KEY, Date.now());
    setShowPolicy(false);
  };

  /* eslint react/prop-types: 0 */
  const renderHyperlink = ({ data: { uri } }, children) => (
    <Link className={styles.link} to={uri}>
      {children}
    </Link>
  );

  return showPolicy ? (
    <div
      {...className(styles.cookieConsent, customClassName)}
      aria-label={'cookies'}
      data-testid={'CookieConsent'}>
      <div className={styles.text}>
        <span className={styles.headline}>{headline} </span>
        {intro && renderText(intro, { renderNode: { [INLINES.HYPERLINK]: renderHyperlink } })}
      </div>
      <button className={styles.closeButton} onClick={onAccept} aria-label="Close">
        <Close className={styles.svg} />
      </button>
    </div>
  ) : null;
};

CookieConsent.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  intro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
};

export default CookieConsent;
