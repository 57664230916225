import { useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { safeGet, safeGetForLanguage } from 'utils';
import { EMBEDDED_ENTRY_TYPES } from 'utils/constants';

const useBigList = richText => {
  /**
   * Retrieves the links to render from the the links
   * body in the Contentful Site model.
   *
   * @returns {object[]} the nav links array
   */
  const getTwoUpList = () => {
    const links = [];

    /**
     * Adds a link to the links array.
     *
     * @param {string|string[]} heading the heading to display
     * @param {string|string[]} body the text to display
     * @param {string} link the link to redirect to
     */
    const addList = ({ data: { target } }) => {
      const { id: contentType, fields } = safeGet(
        target,
        ['sys', 'contentType', 'sys', 'id'],
        ['fields'],
      );

      if (contentType === EMBEDDED_ENTRY_TYPES.BIG_LIST) {
        const { type, items } = safeGetForLanguage(fields, ['type'], ['items']);

        if (type === 'Two Up List') {
          items.forEach(item => {
            links.push({
              heading: item[0],
              body: item[1],
              link: item[2],
            });
          });
        }
      }
    };

    documentToReactComponents(richText.json, {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: addList,
      },
    });

    return links;
  };

  const [links] = useState(getTwoUpList());

  return links;
};

export default useBigList;
