import React from 'react';
import { className, safeGet, getQueryParams, renderText } from 'utils';
import styles from './timelineItem.module.scss';
import PropTypes from 'prop-types';

const TimelineItem = props => {
  const { url: imageURL, description: imageDescription, title, content } = safeGet(
    props,
    ['image', 'file', 'url'],
    ['image', 'description'],
    'title',
    'content',
  );

  return (
    <div {...className(styles.item)}>
      {title && (
        <h3 className={styles.title} data-ignore-heading="true">
          {title}
        </h3>
      )}
      {content && renderText({ json: content })}
      {imageURL && (
        <img
          className={styles.featuredImg}
          src={`${imageURL}${getQueryParams({ w: 400, fit: 'fill' })}`}
          alt={imageDescription || title}
        />
      )}
    </div>
  );
};

TimelineItem.propTypes = {
  props: PropTypes.object,
};

export default TimelineItem;
