import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Player from 'react-player';
import { BASE_PATHS, className, safeGet, renderText } from 'utils';
import { imagePropTypes, videoPropTypes } from 'utils/proptypes';
import { useLocation } from '@reach/router';
import PlayIcon from './PlayIcon';

import styles from './videoPlayer.module.scss';

const addProtocol = url => (url.startsWith('http') ? url : `https:${url}`);

const VideoPlayer = ({
  url,
  cover,
  contentfulVideo,
  caption,
  title,
  intro,
  isHeroCarousel,
  transcriptHeadline,
  transcriptItems,
  showTranscript,
  ...props
}) => {
  const [started, setStarted] = useState(false);
  const location = useLocation();

  const { url: imageUrl, title: imageTitle, description: imageDescription } = safeGet(
    cover,
    ['file', 'url'],
    'title',
    'description',
  );
  const { url: contenfulVideoUrl } = safeGet(contentfulVideo, ['file', 'url']);

  const videoUrl = contenfulVideoUrl || url;
  const hasTranscript = !!transcriptHeadline && transcriptItems?.length;

  if (!videoUrl) {
    return null;
  }

  const isArticle = location.pathname.includes(BASE_PATHS.insights);

  return (
    <figure
      {...className(
        !isArticle && 'component',
        showTranscript ? styles.videoComponentFullWidth : styles.videoComponent,
      )}>
      {title ||
        (intro && (
          <section className={styles.wrapOuter} aria-label="application" data-is-video="true">
            <div 
              {...className(
                styles.wrapInner,
                hasTranscript && showTranscript ? styles.wrapOuterLimit : '',
          )}>            
              {title && (
                <h1 className={styles.title} data-ignore-heading="true">
                  {title}
                </h1>
              )}
              {intro && <p className={styles.intro}>{intro}</p>}
            </div>
          </section>
        ))}        
      <section className={styles.wrapOuter} aria-label="application">
        <div
          {...className(
            styles.wrapInner,
            hasTranscript && showTranscript ? styles.wrapOuterFull : '',
          )}>
          <div className={hasTranscript && showTranscript ? styles.playerWrapOuter : ''}>
            <div className={isHeroCarousel ? styles.playerWrapHero : styles.playerWrap}>
              <Player
                className={styles.player}
                url={addProtocol(videoUrl)}
                playing={started}
                controls
                height="100%"
                width="100%"
                {...props}
              />
              {!started && imageUrl && (
                <Fragment>
                  <img
                    className={styles.cover}
                    src={imageUrl}
                    alt={imageDescription || imageTitle}
                  />
                  <PlayIcon className={styles.playIcon} />
                  <button
                    className={styles.button}
                    aria-label="Play Video"
                    onClick={() => setStarted(true)}
                  />
                </Fragment>
              )}
            </div>
          </div>
          {hasTranscript && showTranscript && (
            <div className={styles.transcriptWrapOuter}>
              <div className={styles.transcriptWrap}>
                <p className={styles.transcriptHeader}>{transcriptHeadline || 'Transcript'}</p>
                <div className={styles.transcriptBody}>
                  {transcriptItems.map((item, index) => (
                    <div key={`transcript-${index}`} className={styles.transcriptItem}>
                      <p className={styles.timestamp}>{item[0]}</p>
                      <p className={styles.description}>{item[1]}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className={styles.wrapOuter} aria-label="application">
        <div {...className(styles.wrapInner, hasTranscript && styles.wrapOuterLimit)}>
          {caption && (
            <figcaption className={styles.caption}>{renderText({ json: caption })}</figcaption>
          )}
        </div>
      </section>
    </figure>
  );
};

VideoPlayer.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  url: PropTypes.string,
  cover: imagePropTypes,
  contentfulVideo: videoPropTypes,
  caption: PropTypes.object,
  transcriptHeadline: PropTypes.string,
  transcriptItems: PropTypes.array,
  showTranscript: PropTypes.bool,
  isHeroCarousel :  PropTypes.bool,
};

export default VideoPlayer;