import styles from './navigation.module.scss';
import { className } from 'utils';
import PropTypes from 'prop-types';
import React from 'react';

const Navigation = ({
  next,
  prev,
  carouselTitleId,
  slideCount = 0,
  isHeroCarousel = false,
  isPathwayCarousel = false,
}) => {
  const dots = () => {
    const a = [...Array(slideCount)];
    const r = a.map((item, key) => (
      <button
        className={styles.navDot}
        aria-current={key === 0}
        data-key={key}
        key={key}
        aria-label={`Go to Slide ${key + 1}`}>
        •
      </button>
    ));
    return r;
  };

  return (
    <nav
      {...className(styles.nav, isHeroCarousel && styles.hasDots)}
      aria-labelledby={carouselTitleId}>
      <button
        aria-label="Previous Slide"
        aria-disabled={isHeroCarousel || isPathwayCarousel}
        onClick={prev}
        className={styles.leftStep}>
        <span>&larr;</span>
      </button>
      {isHeroCarousel && dots()}
      <button aria-label="Next Slide" onClick={next} className={styles.rightStep}>
        <span>&rarr;</span>
      </button>
    </nav>
  );
};

Navigation.propTypes = {
  carouselTitleId: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  slideCount: PropTypes.number,
  isHeroCarousel: PropTypes.bool,
  isPathwayCarousel: PropTypes.bool,
};

export default Navigation;
