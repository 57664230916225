import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './stickyNavigation.module.scss';
import { className, renderText, toVariableName } from '../../utils';
import { useStaticQuery, graphql } from 'gatsby';

const StickyNavigation = ({ title, content }) => {
  const { global } = useStaticQuery(graphql`
    query GlobalStickyNavigationQuery {
      global: contentfulGlobal {
        siteContext
      }
    }
  `);

  const isMicrosite = global?.siteContext[0] === 'Micro Site';
  const [stuck, setStuck] = useState(false);
  const stickyRef = useRef(null);

  useEffect(() => {
    const stickyNav = stickyRef?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        const isStuck = e.intersectionRatio > 0;
        setStuck(isStuck);
      },
      { threshold: [0], root: null, rootMargin: '0px 0px -99% 0px' },
    );

    stickyNav && observer.observe(stickyNav);

    return () => {
      observer.unobserve(stickyNav);
    };
  }, [stickyRef]);

  return (
    <div
      data-automation-id={toVariableName(title)}
      ref={stickyRef}
      {...className(
        'component',
        styles.stickyNavigation,
        stuck ? styles.stuck : '',
        isMicrosite && styles.msOverride,
      )}>
      {renderText({ json: content })}
    </div>
  );
};
StickyNavigation.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
};

export default StickyNavigation;
