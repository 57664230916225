import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { BASE_PATHS, className, getQueryParams, safeGet } from 'utils';
import isEmpty from 'lodash.isempty';
import AuthorTease from './AuthorTease';
import { articleStubPropTypes } from 'utils/proptypes';
import { IMAGE_DIMENSIONS } from 'utils/constants';
import { useIsMobile } from 'hooks';
import styles from './articleTopper.module.scss';
import getRichTextReadingTime from 'utils/getRichTextReadingTime';

const ArticleTopper = ({ article, featured }) => {
  const isMobile = useIsMobile();

  if (isEmpty(article)) {
    return null;
  }

  const {
    title,
    slug,
    date,
    author,
    intro,
    displayIntroOnArticlePage,
    topic,
    articleImage,
    content,
  } = safeGet(
    article,
    'title',
    'slug',
    'date',
    'author',
    'content',
    ['intro', 'intro'],
    'displayIntroOnArticlePage',
    { query: ['topics', 0], name: 'topic' },
    'articleImage',
  );

  const { title: topicTitle, description: topicDescription, url: topicImageUrl } = safeGet(
    topic,
    ['image', 'title'],
    ['image', 'description'],
    ['image', 'file', 'url'],
  );

  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    articleImage,
    ['title'],
    ['description'],
    ['file', 'url'],
  );

  const readingTime = getRichTextReadingTime(content);

  let displayIntro = false;
  displayIntro = displayIntroOnArticlePage;

  const imageSize = isMobile ? { w: 500, h: 300, fit: 'fill' } : IMAGE_DIMENSIONS;

  return (
    <div className="page-left-inset page-left-inset-topper">
      <section className={styles.topperWrapper}>
        <header className={styles.header}>
          {featured && (
            <h1 className={styles.featuredFlag} data-ignore-heading="true">
              <span>Featured Insight</span>
            </h1>
          )}

          <div className={styles.article}>
            <h2
              {...className(styles.title, !featured && styles.withRule)}
              data-ignore-heading="true">
              {featured ? (
                <Link to={`/${BASE_PATHS.insights}/${slug}`} className={styles.titleLink}>
                  {title}
                </Link>
              ) : (
                <span className={styles.titleContent}>{title}</span>
              )}
            </h2>

            {((intro && displayIntro) || (intro && featured)) && (
              <div {...className(styles.intro, !featured && styles.large)}>{intro}</div>
            )}
            <AuthorTease author={author} date={date} topic={topic} readingTime={readingTime} />
          </div>
        </header>
        {imageUrl ? (
          <span className={styles.topicIllustration}>
            <img
              src={`${imageUrl}${getQueryParams(imageSize)}`}
              alt={imageDescription || imageTitle}
            />
          </span>
        ) : (
          topicImageUrl &&
          (featured ? (
            <Link className={styles.topicIllustration} to={`/${BASE_PATHS.insights}/${slug}`}>
              <img
                src={`${topicImageUrl}${getQueryParams(imageSize)}`}
                alt={topicDescription || topicTitle}
              />
            </Link>
          ) : (
            <span className={styles.topicIllustration}>
              <img
                src={`${topicImageUrl}${getQueryParams(imageSize)}`}
                alt={topicDescription || topicTitle}
              />
            </span>
          ))
        )}
      </section>
    </div>
  );
};

ArticleTopper.propTypes = {
  featured: PropTypes.bool,
  article: articleStubPropTypes,
};

export default ArticleTopper;
