import React, { Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { safeGet, renderText, className } from 'utils';
import { imagePropTypes, videoPropTypes } from 'utils/proptypes';
import { carouselImageParams } from 'utils/imageQueryParams';
import { VideoPlayer } from 'components';
import styles from './slide.module.scss';
import { Link } from 'gatsby';
import WideRightArrow from '../../../svgs/Arrow/WideRight';

const Slide = ({
  image,
  video,
  link,
  caption,
  title,
  subtitle,
  summary,
  isHeroCarousel = false,
  isPathwayCarousel = false,
}) => {
  if (!isPathwayCarousel) {
    const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
      image,
      'title',
      'description',
      ['file', 'url'],
    );
    const jsonCaption = get(caption, 'json', caption);
    const imageContent = imageUrl && (
      <Fragment>
        <div className={styles.imageWrapper}>
          <div className={styles.clippingBox}>
            <img
              className={styles.image}
              src={`${imageUrl}${carouselImageParams}`}
              alt={imageDescription || imageTitle}
            />
          </div>
        </div>
        {jsonCaption && (
          <figcaption className={styles.caption}>{renderText({ json: jsonCaption })}</figcaption>
        )}
      </Fragment>
    );
    const videoContent = video && (
      <Fragment>
        <div {...className(styles.imageWrapper, isHeroCarousel && styles.heroCarousel)}>
          <div className={styles.clippingBox}>
            <VideoPlayer {...video} cover={video.coverImage} isHeroCarousel={isHeroCarousel} />
          </div>
        </div>
        {jsonCaption && (
          <figcaption className={styles.caption}>{renderText({ json: jsonCaption })}</figcaption>
        )}
      </Fragment>
    );

    return (
      <figure {...className(styles.slide, isHeroCarousel && styles.isHeroCarousel)}>
        {link ? (
          <Link to={link} className={styles.linkWrapper}>
            {imageContent}
          </Link>
        ) : (
          image && imageContent
        )}
        {video && videoContent}
      </figure>
    );
  } else {
    return (
      <div className={styles.pathwaySlide}>
        <div className={styles.pathwayInner}>
          {link ? (
            <Link to={link}>
              <h3 className={styles.slideTitle}>
                <span>{title}</span>
                <WideRightArrow />
              </h3>
            </Link>
          ) : (
            <h3 className={styles.slideTitle}>{title}</h3>
          )}
          <h4 className={styles.slideSubtitle}>{subtitle}</h4>
          <p className={styles.slideSummary}>{summary}</p>
        </div>
      </div>
    );
  }
};

Slide.propTypes = {
  caption: PropTypes.object,
  image: imagePropTypes,
  video: videoPropTypes,
  link: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  summary: PropTypes.string,
  isHeroCarousel: PropTypes.bool,
  isPathwayCarousel: PropTypes.bool,
};

export default Slide;
