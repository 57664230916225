import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import styles from './iconCTA.module.scss';
import IconCTAGridItem from './IconCTAGridItem';

const IconCTA = props => {
  const columnData = [1, 2, 3, 4].map(index => ({
    image: props[`image${index}`],
    intro: props[`intro${index}`],
    ctaUrl: props[`url${index}`],
  }));

  return (
    <ul {...className(styles.wrapper)}>
      {isNonEmptyArray(columnData) &&
        columnData.map((data, i) => <IconCTAGridItem key={i} {...data} />)}
    </ul>
  );
};

IconCTA.propTypes = {
  props: PropTypes.object,
};

export default IconCTA;
