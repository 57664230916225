import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { useForceUpdate } from 'hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carousel.module.scss';
import Navigation from './Navigation/index';

const Carousel = ({
  children,
  className: customClassName,
  customOptions,
  title,
  description,
  topRightButton,
  name,
  isPathwayCarousel = false,
  isHeroCarousel = false,
  isMicrosite = false,
}) => {
  const VAR_BP_SM = parseInt(styles.varBpSm);
  const VAR_BP_MD_SM = parseInt(styles.varBpMdSm);
  const VAR_BP_MD_LG = parseInt(styles.varBpMdLg);

  // if set count more than available - slides will be duplicated
  const getCorrectCountOfSlidesToShow = number =>
    children?.length > number ? number : children?.length;

  const actualSlideCount = children?.length;

  const showslidesMax = isHeroCarousel ? 1.1 : isPathwayCarousel ? 3.4 : 4;
  const showSlidesMdLg = isHeroCarousel ? 1 : isPathwayCarousel ? 2 : 3;
  const showSlidesMdSm = isHeroCarousel || isPathwayCarousel ? 1 : 2;

  const defaultOptions = {
    adaptiveHeight: false,
    accessibility: true,
    arrows: false,
    draggable: true,
    easing: 'in-out',
    slidesToScroll: 1,
    slidesToShow: showslidesMax,
    swipeToSlide: true,
    infinite: !isPathwayCarousel && !isHeroCarousel,
    variableWidth: false,
    responsive: [
      {
        breakpoint: VAR_BP_MD_LG,
        settings: {
          slidesToShow: getCorrectCountOfSlidesToShow(showSlidesMdLg),
        },
      },
      {
        breakpoint: VAR_BP_MD_SM,
        settings: {
          slidesToShow: getCorrectCountOfSlidesToShow(showSlidesMdSm),
        },
      },
      {
        breakpoint: VAR_BP_SM,
        settings: {
          centerMode: true,
          slidesToShow: getCorrectCountOfSlidesToShow(1),
        },
      },
    ],
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const carouselRef = useRef();
  const sliderRef = useRef();
  const nextSlide = useRef(() => {});
  const prevSlide = useRef(() => {});

  const update = useForceUpdate();

  const [dragging, setDragging] = useState(false);
  const [slidesCountOnPage, setSlidesCount] = useState(defaultOptions.slidesToShow);

  const slidesMoreThanShowing = slidesCountOnPage < children.length && children.length > 3;

  // Used to point the aria-labelledby attribute of the carousel's <nav />
  // to this carousel's <h1 />.
  // An accessibility precaution in case a page has more than one carousel.
  // If there's no title, use the name field from Contentful
  let carouselTitleId;
  let carouselIdRaw;
  title ? (carouselIdRaw = title) : (carouselIdRaw = name);

  carouselIdRaw &&
    (carouselTitleId = `carousel-${carouselIdRaw
      .replace(/[^\w\s]|_/g, '_') // replace all punctuation with underscores
      .split(' ') // convert to array of words
      .slice(0, 3) // get the first 3 words
      .join('-')}`); // join into a kebab case id

  const unfocusHiddenAnchorTags = () => {
    if (carouselRef.current) {
      Array.from(
        carouselRef.current
          .querySelector('.slick-slider')
          .querySelectorAll('div[aria-hidden="true"] a'),
      ).forEach(a => (a.tabIndex = -1));
    }
  };

  const handleBeforeChange = useCallback(
    (oldIndex, newIndex) => {
      setDragging(true);
      if (isHeroCarousel) {
        document
          .querySelectorAll(`nav[aria-labelledby="${carouselTitleId}"] [data-key]`)
          .forEach(dot => {
            dot.setAttribute('aria-current', 'false');
          });
        document
          .querySelector(`nav[aria-labelledby="${carouselTitleId}"] [data-key="${newIndex}"]`)
          .setAttribute('aria-current', 'true');
        if (newIndex >= children.length - slidesCountOnPage) {
          for (let n = newIndex + 1; n < newIndex + slidesCountOnPage && n < children.length; n++) {
            document
              .querySelector(`nav[aria-labelledby="${carouselTitleId}"] [data-key="${n}"]`)
              .setAttribute('aria-current', 'true');
          }
        }
      }
      document
        .querySelector(`#${carouselTitleId}-container [aria-label="Previous Slide"]`)
        .setAttribute('aria-disabled', 'false');
      document
        .querySelector(`#${carouselTitleId}-container [aria-label="Next Slide"]`)
        .setAttribute('aria-disabled', 'false');
      if (newIndex === 0) {
        document
          .querySelector(`#${carouselTitleId}-container [aria-label="Previous Slide"]`)
          .setAttribute('aria-disabled', 'true');
      }
      if (newIndex >= actualSlideCount - slidesCountOnPage) {
        document
          .querySelector(`#${carouselTitleId}-container [aria-label="Next Slide"]`)
          .setAttribute('aria-disabled', 'true');
      }
    },
    [setDragging],
  );

  const handleAfterChange = useCallback(() => {
    setDragging(false);
    unfocusHiddenAnchorTags();
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    e => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging],
  );

  const updateSlidesCountOnPage = () => {
    setSlidesCount(
      defaultOptions.responsive.find(s => s.breakpoint === sliderRef.current.state.breakpoint)
        ?.settings?.slidesToShow || defaultOptions.slidesToShow,
    );
  };

  const dotJumpToSlide = key => {
    sliderRef.current.slickGoTo(key);
  };

  useEffect(() => {
    if (sliderRef.current) {
      nextSlide.current = sliderRef.current.slickNext;
      prevSlide.current = sliderRef.current.slickPrev;
      document
        .querySelectorAll(`nav[aria-labelledby="${carouselTitleId}"] [data-key]`)
        .forEach(dot => {
          dot.setAttribute('data-added-click', 'true');
          const dotKey = dot.getAttribute('data-key');
          dot.addEventListener('click', () => {
            dotJumpToSlide(dotKey);
          });
        });
      updateSlidesCountOnPage();
      unfocusHiddenAnchorTags();
      update();
    }
  }, [sliderRef.current]);

  useEffect(() => {
    function handleResize() {
      updateSlidesCountOnPage();
      update();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section
      {...className(
        customClassName,
        styles.outerWrapper,
        isPathwayCarousel && styles.pathwayCarousel,
        isHeroCarousel && styles.heroCarousel,
        isMicrosite && styles.isMicrosite,
      )}
      ref={carouselRef}
      id={`${carouselTitleId}-container`}>
      <header {...className(styles.header, title && styles.headerPadding, 'page-left-inset')}>
        <h1
          id={carouselTitleId}
          {...className(styles.heading, topRightButton && styles.small, !title && styles.hide)}>
          {title}
        </h1>

        {topRightButton
          ? !isPathwayCarousel && (
              <div {...className(styles.buttonWrapper, styles.desktopOnly)}>{topRightButton}</div>
            )
          : carouselTitleId &&
            slidesMoreThanShowing && (
              <Navigation
                prev={prevSlide.current}
                next={nextSlide.current}
                carouselTitleId={carouselTitleId}
              />
            )}
        {description && <p className={styles.description}>{description}</p>}
      </header>
      <Slider
        className={[styles.slider, isPathwayCarousel && styles.pathwaySlider, carouselTitleId]}
        {...options}
        ref={sliderRef}
        beforeChange={(oldIndex, newIndex) => {
          handleBeforeChange(oldIndex, newIndex);
        }}
        afterChange={handleAfterChange}>
        {React.Children.map(children, child => (
          <div
            {...className(styles.slideWrapper, isHeroCarousel && styles.isHeroCarousel)}
            onClickCapture={handleOnItemClick}>
            {child}
          </div>
        ))}
      </Slider>
      {topRightButton && slidesMoreThanShowing && (
        <div
          {...className(styles.footer, 'page-left-inset', isHeroCarousel && styles.isHeroCarousel)}>
          {!isPathwayCarousel && (
            <div
              {...className(
                styles.buttonWrapper,
                styles.mobileOnly,
                isPathwayCarousel && styles.pathwayButton,
              )}>
              {topRightButton}
            </div>
          )}
          {!isPathwayCarousel && (
            <Navigation
              prev={prevSlide.current}
              next={nextSlide.current}
              carouselTitleId={carouselTitleId}
              slideCount={actualSlideCount}
              isHeroCarousel={isHeroCarousel}
              isPathwayCarousel={isPathwayCarousel}
            />
          )}
        </div>
      )}
      {isPathwayCarousel && (
        <div className={styles.pathwayNavAssembly}>
          {topRightButton && (
            <div {...className(styles.buttonWrapper, styles.pathwayButton)}>{topRightButton}</div>
          )}
          <Navigation
            prev={prevSlide.current}
            next={nextSlide.current}
            carouselTitleId={carouselTitleId}
            slideCount={actualSlideCount}
            isHeroCarousel={isHeroCarousel}
            isPathwayCarousel={isPathwayCarousel}
          />
        </div>
      )}
    </section>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  customOptions: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  topRightButton: PropTypes.node,
  name: PropTypes.string,
  isPathwayCarousel: PropTypes.bool,
  isHeroCarousel: PropTypes.bool,
  isMicrosite: PropTypes.bool,
};

export default Carousel;
