import React, { useState, useEffect, forwardRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { useLinks, useBigList } from 'hooks';
import { Wordmark } from 'svgs';
import { Link } from 'components';
import DesktopNav from './Desktop';
import MobileNav from './Mobile';
import styles from './navigation.module.scss';
import { className } from '../../utils';

const VAR_BP_NAV = parseInt(styles.varBpNav);

export const useMenuItems = ({ list, mainDropdownNavigation, menuFormatType, ...rest }) => {
  const [bigsLists] = useState(useBigList(mainDropdownNavigation || {}));
  const [links] = useState(useLinks(list || {}));
  const menuLinks = bigsLists.map(i => ({
    to: i.link,
    text: [i.heading],
    description: i.body,
  }));

  return {
    ...rest,
    menuLinks,
    links,
    menuFormatType: menuFormatType?.length ? menuFormatType[0] : null,
  };
};

const Navigation = forwardRef(({ isMobileMenuOpen, siteTitle }, ref) => {
  const {
    global: { navLinks, menu, buttonText, buttonLink, logoLink, siteContext, searchPlaceholder },
  } = useStaticQuery(graphql`
    query NavQuery {
      global: contentfulGlobal {
        navLinks {
          json
        }
        siteContext
        searchPlaceholder
        menu {
          heading
          headingUrl
          dropdownHeading
          menuFormatType
          list {
            json
          }
          mainDropdownNavigation {
            json
          }
          cta1NavigationImage {
            file {
              url
            }
            title
          }
          cta1NavigationIntro
          cta1NavigationLink
          cta1NavigationButtonText
          cta2NavigationIntro
          cta2NavigationLink
          cta2NavigationButtonText
        }
        buttonText
        buttonLink
        logoLink
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(true);
  const isMicrosite = siteContext && siteContext[0] === 'Micro Site';

  const handleResize = () => {
    setIsMobile(window.innerWidth < VAR_BP_NAV);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navItemsWithDropdowns = menu.map(useMenuItems);
  const utilityLinks = useLinks(navLinks);

  return (
    <header
      {...className(
        styles.header,
        !isMobileMenuOpen && styles.headerMobileMenuClosed,
        isMobileMenuOpen && styles.headerMobileMenuOpen,
        isMicrosite && styles.msHeader,
      )}
      ref={ref}>
      <div className={styles.logoContainer}>
        <Link
          id="logo"
          {...className(styles.wordmarkWrapper, isMobileMenuOpen && styles.linkNotClickable)}
          to={logoLink}
          title={siteTitle}>
          <Wordmark {...className(styles.wordmark)} />
          <span className={styles.visuallyHidden}>Commonwealth Logo</span>
        </Link>
      </div>
      {isMobile && (
        <MobileNav
          navItemsWithDropdowns={navItemsWithDropdowns}
          utilityLinks={utilityLinks}
          menu={menu}
          isMobileMenuOpen={isMobileMenuOpen}
          buttonLink={buttonLink}
          buttonText={buttonText}
          isMicrosite
        />
      )}
      <DesktopNav
        style={isMobile ? { display: 'none' } : {}}
        navItemsWithDropdowns={navItemsWithDropdowns}
        utilityLinks={utilityLinks}
        menu={menu}
        isMobileMenuOpen={isMobileMenuOpen}
        buttonLink={buttonLink}
        buttonText={buttonText}
        isMicrosite={isMicrosite}
        searchPlaceholder={searchPlaceholder}
      />
    </header>
  );
});

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  isMobileMenuOpen: PropTypes.bool.isRequired,
};

Navigation.displayName = 'Navigation';

export default Navigation;
