import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { FacebookLogo, LinkedInLogo, TwitterLogo, Email } from 'svgs';
import { SocialLink } from 'components';
import { className } from 'utils';
import styles from './articleSocialMediaBar.module.scss';

const ArticleSocialMediaBar = ({ className: customClassName, articleTitle, globalUrl }) => {
  const [show, setShow] = useState(true);
  const location = useLocation();

  const handleScroll = useCallback(() => {
    const element = document.getElementById('sidebarBottomLine');
    const rect = element.getBoundingClientRect();
    const showBottomLine =
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    setShow(!showBottomLine);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const url = `${globalUrl}${location.pathname}`;

  if (!show) {
    return null;
  }

  return (
    <ul {...className(customClassName, styles.articleSocialMediaBar)}>
      <SocialLink text="Email" to={`mailto:?subject=${articleTitle}&body=${url}`} logoSvg={Email} />
      <SocialLink
        text="Share to LinkedIn"
        to={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${articleTitle}`}
        logoSvg={LinkedInLogo}
      />
      <SocialLink
        text="Share to Twitter"
        to={`http://twitter.com/share?text=${articleTitle}&url=${url}`}
        logoSvg={TwitterLogo}
      />
      <SocialLink
        text="Share to Facebook"
        to={`http://www.facebook.com/sharer.php?u=${url}&p[title]=${articleTitle}`}
        logoSvg={FacebookLogo}
      />
    </ul>
  );
};

ArticleSocialMediaBar.propTypes = {
  className: PropTypes.string,
  articleTitle: PropTypes.string,
  globalUrl: PropTypes.string,
};

export default ArticleSocialMediaBar;
