/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './keySolutionsCta.module.scss';
import { Link } from 'gatsby';
import InlineIcon from '../InlineIcon';
import InlineFontawesomeIcon from '../InlineFontawesomeIcon';
import { safeGet, safeGetForLanguage, serialize, className } from 'utils';
import { CURRENT_LANGUAGE } from 'utils/constants';

const KeySolutionsCTA = ({ title, subtitle, links, image }) => {
  // Extract values from image object
  const { url: imageURL, description: imageDescription, title: imageTitle } = safeGet(
    image,
    ['file', 'url'],
    ['description'],
    ['title'],
  );

  return (
    <section {...className('component', styles.keySolutionsCta)}>
      <img src={imageURL} alt={imageDescription || imageTitle} />
      <div>
        <hgroup>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
        </hgroup>
        <ul role="list">
          {links.map(link => {
            const { text, url, icon } = link;

            // Determine if icon is a Font Awesome object or an image & standardise data
            const isFontAwesomeIcon = icon.type === 'Font Awesome Icon';
            const { name } = safeGetForLanguage(link, ['name']);
            const serializedIcon = serialize(icon, [CURRENT_LANGUAGE, 'fields']);

            return (
              <li key={name}>
                <Link to={url}>
                  {isFontAwesomeIcon ? (
                    <InlineFontawesomeIcon html={icon.fontawesomeClass} />
                  ) : (
                    <InlineIcon name={name} icon={serializedIcon.icon} />
                  )}
                  <span>{text}</span>
                  <i className="fa-sharp fa-regular fa-arrow-right" />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

KeySolutionsCTA.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  links: PropTypes.array,
  image: PropTypes.object,
};

export default KeySolutionsCTA;
