import React from 'react';
import PropTypes from 'prop-types';
import { className, renderText, isNonEmptyArray } from 'utils';
import { RichTextCarousel, Link } from 'components';
import { HeroPathways } from 'svgs';

import styles from './heroCarousel.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const HeroCarousel = ({ headline, content, buttons, heroCarousel }) => (
  <section
    {...className(
      'component page-left-inset',
      ctaBaseStyles.cta,
      ctaBaseStyles.withBackground,
      styles.heroCarousel,
    )}>
    <div className={styles.heroCarouselPathwaysWrapper}>
      <HeroPathways className={styles.heroCarouselPathways} />
    </div>
    <div className={ctaBaseStyles.row}>
      <div className={styles.left}>
        <div className={styles.copy}>
          {headline && <h1 className={styles.headline}>{headline}</h1>}
          {renderText({ json: content })}
        </div>
        {buttons && isNonEmptyArray(buttons) && (
          <div className={styles.buttonWrapperFull}>
            <Link
              {...className(styles.button, styles.jumpLink)}
              to={buttons[0][1]}
              aria-label={buttons[0][0]}
              newTab>
              ↓
            </Link>
          </div>
        )}
      </div>
      <div className={styles.right}>
        {heroCarousel && <RichTextCarousel {...heroCarousel} isHeroCarousel={true} />}
      </div>
    </div>
  </section>
);

HeroCarousel.propTypes = {
  headline: PropTypes.string,
  content: PropTypes.object,
  buttons: PropTypes.array,
  type: PropTypes.string,
  heroCarousel: PropTypes.object,
};

export default HeroCarousel;
