//import { wait } from '@testing-library/react';
import { useEffect } from 'react';

const HUBSPOT_PORTAL_ID = '295024';
const SCRIPT_ID = 'hubspot-forms-script';
const SCRIPT_SRC = 'https://js.hsforms.net/forms/v2.js';

const createHubspotForm = (formId, target) => {
  // Disabled due to incompatibility with Vimeo React Player videos
  // window.jQuery =
  //   window.jQuery ||
  //   (() => ({
  //     // these are all methods required by HubSpot
  //     change: () => {},
  //     trigger: () => {},
  //   }));
  if (window.hbspt && formId) {
    window.hbspt.forms?.create({
      portalId: HUBSPOT_PORTAL_ID,
      css: '',
      formId,
      target,
      onFormReady: function() {
        function getCookie(cname) {
          const name = `${cname}=`;
          const decodedCookie = decodeURIComponent(document.cookie);
          const ca = decodedCookie.split(';');
          for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
            }
          }
          return '';
        }
        function setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
          const expires = `expires=${d.toUTCString()}`;
          document.cookie = `${cname}=${cvalue};${expires};path=/`;
        }
        const el = document.querySelectorAll("form[id^='hsForm_']");
        el.forEach(element => {
          if (element.querySelector('input[name="google_analytics_uid"]') && getCookie('ruid')) {
            element.querySelector('input[name="google_analytics_uid"]').value = getCookie('ruid');
          } else {
            let ruidCookie = '';
            const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const length = 10;
            for (let i = length; i > 0; --i) {
              ruidCookie += chars[Math.floor(Math.random() * chars.length)];
            }
            setCookie('ruid', ruidCookie, 182); // 182 days ~= 6 months
            if (element.querySelector('input[name="google_analytics_uid"]')) {
              element.querySelector('input[name="google_analytics_uid"]').value = getCookie('ruid');
            }
          }
        });
      },
      onFormSubmit: function($form) {
        $form.submit();
      },
    });
  }
};

const addHubspotScriptListener = (formId, target) => {
  const script = document.createElement('script');

  script.id = `${SCRIPT_ID}-${target}`;
  script.src = SCRIPT_SRC;

  document.body.appendChild(script);

  script.addEventListener('load', () => {
    createHubspotForm(formId, target);
  });
};

const useHubspotForm = (formId, target, transitionStatus) => {
  useEffect(() => {
    if (transitionStatus !== 'entered') {
      return;
    }

    if (document.getElementById(`${SCRIPT_ID}-${target}`)) {
      createHubspotForm(formId, target);
    } else {
      addHubspotScriptListener(formId, target);
    }
  }, []);
};

export default useHubspotForm;
