import React from 'react';

const MediumRight = props => (
  <svg fill="none" {...props} viewBox="0 0 20 11" height={11} width={20}>
    <path
      d="M20 5.26683L14.343 0L12.929 1.21678L16.172 4.2264H0V6.18481H16.172L12.929 9.53046L14.343 11L20 5.26683Z"
      fill="currentColor"
    />
  </svg>
);

export default MediumRight;
