import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';

import Wide from './Wide';
import Hero from './Hero';
import TwoUp from './TwoUp';
import LeftCap from './LeftCap';
import BleedRight from './BleedRight';
import InsetRight from './InsetRight';
import TextWidth from './TextWidth';

const MediaLayout = ({ type, caption, ...props }) => {
  const { global } = useStaticQuery(graphql`
    query GlobalMediaLayoutQuery {
      global: contentfulGlobal {
        siteContext
      }
    }
  `);

  const isMicrosite = global.siteContext[0] === 'Micro Site';

  const mapping = {
    ['Wide']: Wide,
    ['Two Up']: TwoUp,
    ['Bleed Right']: BleedRight,
    ['Inset Right']: InsetRight,
    ['Hero']: Hero,
    ['Left Cap']: LeftCap,
    ['Text Width']: TextWidth,
  };

  const jsonCaption = get(caption, 'json', caption);

  const _component = mapping[type] || Wide;
  return <_component {...props} caption={jsonCaption} isMicrosite={isMicrosite} />;
};

MediaLayout.propTypes = {
  type: PropTypes.string.isRequired,
  caption: PropTypes.object,
};

export default MediaLayout;
