import React from 'react';
import PropTypes from 'prop-types';
import FocusCTAs from './FocusCTAs';

const KeyFocusCTA = ({ ...props }) => <FocusCTAs {...props} />;

KeyFocusCTA.propTypes = {
  ctaType: PropTypes.string,
};

export default KeyFocusCTA;
